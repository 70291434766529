import { useEffect, useState } from 'react'
import { Formik, useMField } from '@mprise/react-ui'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { defined, fail } from '../typescript'
import { useHistory } from '../use-history'
import { useLazyQuery } from '@apollo/client'
import { JOB_INVENTORY_BY_FILTER } from '../../gql/query/jobInventories/jobInventoryByFilter'
import { JobInventoryDetail, JobInventoryGroup } from '../interfaces'
import { JobMutationsEntryForm } from '../../routes/job-mutations/Home'
import { SelectGroupedJobInventoryDialog } from '../dialog/SelectGroupedJobInventoryDialog'

export type EntryFormType = JobMutationsEntryForm

export const FieldJobInventorySmart = ({ title }: { title: string }) => {
  const f = useMField()
  const fc = Formik.useFormikContext<EntryFormType>()
  const h = useHistory()
  const { company: currentCompany } = useAppSettingsContext()
  const companyId = currentCompany?.id ? +currentCompany.id : h.push('/')

  const jobId = fc.values.job?.id

  const hasSelectedJid = !!fc.values.selectedJobInventories.length

  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const [getJobInventoryDetails, jobInventoryDetailsQuery] = useLazyQuery(JOB_INVENTORY_BY_FILTER)

  useEffect(() => {
    if (hasSelectedJid) {
      return
    }
    if (jobId) {
      if (!companyId) {
        fail('expects company id')
      }

      getJobInventoryDetails({
        variables: {
          filter: {
            companyId: companyId,
            jobId: +jobId,
            positionId: null,
            includeCarrierCodes: true,
          },
        },
        fetchPolicy: 'no-cache',
      })
    }
  }, [jobId, hasSelectedJid])

  const jobInventoryDetails = jobInventoryDetailsQuery.data?.jobInventoryDetails?.filter(defined) ?? []

  const [groupedJids, setGroupedJids] = useState<JobInventoryGroup[]>([])

  useEffect(() => {
    if (jobInventoryDetailsQuery.data) {
      if (jobInventoryDetails.length === 0) {
        f.onChange?.([])
      } else if (jobInventoryDetails.length === 1) {
        f.onChange?.(jobInventoryDetails)
      } else {
        const groupedJids: any[] = []

        Map.groupBy(
          jobInventoryDetails as any[],
          x =>
            `${x.job.id}-${x.position.id}-${x.productionPhase.productionPhase}-${x.pillar}-${x.carrierCode}-${x.unitOfMeasure}`,
        ).forEach((group, idx) => {
          const combinedQuantity = group.reduce((acc: any, jid: any) => acc + (jid.remainingQuantity ?? 0), 0)
          const sortingCodes = group.flatMap(jid => jid.sortingCode).join(', ')
          // maybe multiple sortings, make group
          const grouped = {
            ...group[0],
            id: `group-${idx}`,
            remainingQuantity: combinedQuantity,
            sortingCode: sortingCodes,
            multipleJids: group,
          } as JobInventoryGroup
          groupedJids.push(grouped)
        })

        setGroupedJids(groupedJids)
        setOpen(true)
      }
    }
  }, [jobInventoryDetailsQuery])

  /** Prefill job and fromPosition when jobInventory is selected. */
  useEffect(() => {
    if (fc.values.selectedJobInventories.length) {
      prefillForm(fc, fc.values.selectedJobInventories)
    }
  }, [fc.values.selectedJobInventories])

  return (
    <>
      {jobInventoryDetailsQuery.data && jobInventoryDetails.length !== 1 && (
        <SelectGroupedJobInventoryDialog
          open={open}
          jobInventoryGroups={groupedJids}
          onClose={handleClose}
          onSave={(groupedJids: JobInventoryGroup) => {
            f.onChange?.(groupedJids.multipleJids)
            setOpen(false)
          }}
          title={title}
        />
      )}
    </>
  )
}

function prefillForm(fc: Formik.FormikContextType<EntryFormType>, jids: JobInventoryDetail[]) {
  const jid = fc.values.selectedJobInventories[0]
  if (jid?.job && jid.position) {
    fc.setValues(current => ({
      ...current,
      job: {
        id: jid.job.id!,
        name: jid.job.name!,
        code: jid.job.code!,
      },
      fromPosition: {
        id: jid.position.id!,
        name: jid.position.name!,
        code: jid.position.code!,
      },
      originalQuantity: jids.reduce((a, b) => a + b.remainingQuantity, 0),
    }))
  }
}
