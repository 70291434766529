import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import { SortAndTransferForm } from './SortAndTransferForm'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { parseFloatQuantity } from '../../../shared/formats'
import { Maybe } from '../../../shared/enums'
import { JobInventoryDetail } from '../../../shared/interfaces'
import { fail } from '../../../shared/typescript'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { REPORT_SORT_AND_TRANSFER } from '../../../gql/mutation/reportSortAndTransferToPosition'

export interface SortAndTransferEntryForm {
  jobInventoryPutAways: Array<{
    toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
    quantity: Maybe<number>
    quantityUnit: Maybe<string>
    sorting: Maybe<string>
    plannedQuantity: Maybe<number>
  }>
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  workItemId: Maybe<string>
  workItemCosmosKey: Maybe<string>
  taskCosmosKey: Maybe<string>
  task: Maybe<any>
}

export const SortAndTransferRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = SortAndTransferForm.useSchema()

  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  const taskId = useParams().taskId ?? h.push('/')

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })

  const task = taskQuery.data?.nworkTask
  const workItemId = task?.workItem.id
  const workItemCosmosKey = task?.workItem.cosmosKey
  const taskCosmosKey = task?.cosmosKey

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const handleClose = async (autoFinished?: boolean, e?: React.FormEvent<Element>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (workItemId && taskId && !autoFinished) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        stopTimeReg({
          variables: {
            workItemId: workItemCosmosKey,
            taskId: taskCosmosKey,
          },
        })
      })
    }
    h.goBack()
  }

  const [reportSortAndTransfer, reportSortAndTransferState] = useMutation(REPORT_SORT_AND_TRANSFER)

  const [initialValues] = useLocalState((): SortAndTransferEntryForm => {
    const job = (task?.workItem.jobs ?? [])[0]
    return withFormikCompareFix({
      job: { id: job?.id, name: job?.name, code: job?.code },
      jobInventoryDetail: null,
      task: task,
      workItemId: workItemId,
      workItemCosmosKey: workItemCosmosKey,
      taskCosmosKey: taskCosmosKey,
      jobInventoryPutAways:
        task?.workItem.jobInventoryPutAway.map(
          (i: {
            id: any
            planned: {
              position: any
              quantity: any
              quantityUnit: string
              sorting: string
            }
          }) => {
            return {
              taskResultId: i.id,
              toPosition: { id: i.planned.position.id, name: i.planned.position.name, code: i.planned.position.code },
              quantity: i.planned.quantity,
              quantityUnit: i.planned.quantityUnit,
              sorting: i.planned.sorting,
              plannedQuantity: i.planned.quantity,
            }
          },
        ) ?? [],
    })
  }, [])

  const handleSubmit = async (form: any) => {
    let autoFinished = false

    if (!resourceId) {
      fail('expects resource id')
    }
    const originJobInventoryDetailId = form.jobInventoryDetail.id
    const sortAndTransferDestinations = (form.jobInventoryPutAways ?? [])
      .filter((x: any) => x.quantity > 0)
      .map((x: any) => ({
        sorting: x.sorting,
        quantity: parseFloatQuantity(x.quantity, numberFormat),
        positionId: +x.toPosition.id,
      }))

    const phaseCode = task?.workItem?.phaseCode

    await reportSortAndTransfer({
      variables: {
        originJobInventoryDetailId: +originJobInventoryDetailId,
        sortAndTransferDestinations: sortAndTransferDestinations,
        resourceId: +resourceId!,
        destinationPhase: phaseCode,
        workItemId: +workItemId,
        taskId: +taskId,
      },
    }).then(response => {
      autoFinished = response.data.sortAndTransferPositionJobInventoryGH.autoFinished
      if (autoFinished) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
      }
      if (response.data) {
        MAudio.scanSuccess()
        alerts.push(t('SUCCESS_MESSAGE'), `success`)
      }
    })

    if (form.workItemCosmosKey && form.taskCosmosKey && autoFinished) {
      stopTimeReg({
        variables: {
          workItemId: workItemCosmosKey,
          taskId: taskCosmosKey,
        },
      })
    }
    handleClose(autoFinished)
  }

  return (
    <>
      <Helmet title={t('SORTING')} />
      <Formik.Formik enableReinitialize initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('SORTING')} onClose={e => handleClose(false, e)} open={true}>
          <SavingSwitchPanel mutation={[reportSortAndTransferState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage mutation={[reportSortAndTransferState, stopTaskState]} />
            <SortAndTransferForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
