import { useTranslation } from 'react-i18next'
import { MDialog, MDialogContentText } from '../../mprise-light/Dialog'
import { MutationErrorMessage } from '../../shared/apollo'
import { MutationResult } from '@apollo/client'
import { MColor, MText } from '@mprise/react-ui'
import { formatUnitOfMeasure } from '../../shared/formats'
import IconWarning from '@mui/icons-material/Warning'
import { Maybe } from '../../shared/enums'

export const ConfirmFinishDialog = ({
  task,
  open,
  reportedQuantity,
  plannedQuantity,
  onConfirmFinish,
  onCancelFinish,
  finishTaskState,
}: {
  task: any
  open: boolean
  reportedQuantity: Maybe<number>
  plannedQuantity: number
  onConfirmFinish: () => void
  onCancelFinish: () => void
  finishTaskState: MutationResult<any>
}) => {
  const { t } = useTranslation()

  if (open && reportedQuantity !== plannedQuantity) {
    return (
      <MDialog open={open} title={t('CONFIRM')} onSubmit={onConfirmFinish} onClose={onCancelFinish}>
        <MutationErrorMessage mutation={finishTaskState} />
        <MDialogContentText>
          <div style={{ padding: '1rem' }}>
            <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '1rem' }}>
              <IconWarning style={{ color: MColor.medium }} />
              <span style={{ paddingLeft: '0.5rem' }}>
                {t('QUANTITY_INCOMPLETE_PLANNED_REPORTED_UNIT', {
                  QTY_PLANNED: plannedQuantity.toLocaleString('nl-NL'),
                  QTY_REPORTED: reportedQuantity?.toLocaleString('nl-NL'),
                  UNIT: formatUnitOfMeasure(task?.workItem?.unitOfMeasure),
                })}
              </span>
            </div>
            <MText block>{t('CONFIRM_FINISH_ARE_YOU_SURE')}</MText>
          </div>
        </MDialogContentText>
      </MDialog>
    )
  } else {
    return <></>
  }
}
