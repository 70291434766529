import { Formik, MAudio, MFieldConnector, MFlexBlock, MFlexItem, MText, MTextColor, parseDate } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { DialogFormik } from '../../mprise-light/DialogFormik'
import { Section } from '../../components/Section'
import { LocalDate, Period } from '@js-joda/core'
import { nameof } from '../../shared/typescript'
import { FlashAlerts } from '../../shared/flash-alerts'
import { FieldDate } from '../../mprise-light/FieldDate'
import { fail } from '../../shared/typescript'
import { useMutation } from '@apollo/client'
import { UPDATE_JOB_PRODUCTION_PHASES } from '../../gql/mutation/updateJobProductionPhases'
import { JOB_WITH_PHASES } from '../../gql/query/jobs/jobById'
import { Maybe } from '../../shared/enums'
import { MutationErrorMessage } from '../../shared/apollo'
import { JobProductionPhase } from '../../shared/interfaces'

export interface EditJobPhaseForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }>
  startDate: LocalDate
  phaseId: string
  parentLineNo: number
}

export const EditPhaseStartDateDialog = ({
  phaseText,
  phase,
  startingDate,
  unfilteredPhases,
  open,
  handleClose,
}: {
  phaseText: string
  phase: JobProductionPhase
  startingDate: string
  unfilteredPhases: JobProductionPhase[]
  open: boolean
  handleClose: () => void
}) => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()

  const fc = Formik.useFormikContext<EditJobPhaseForm>()
  const jobId = fc.values.job?.id

  const startDate = parseDate(startingDate)

  const initialValues = {
    startDate: startDate!,
    phaseId: phase.id,
    parentLineNo: phase.parentLineNo,
    job: fc.values.job,
  }

  const [updateJobProductionPhases, updateJobProductionPhasesState] = useMutation(UPDATE_JOB_PRODUCTION_PHASES, {
    refetchQueries: [JOB_WITH_PHASES],
  })

  const handleSubmitChangeStartDate = async (form: EditJobPhaseForm) => {
    if (!jobId) {
      fail('expects job id')
    }

    // change start and enddate for phases current and in the future. Change enddate of the previous phase.
    const dateChange = Period.between(startDate!, form.startDate)

    const changedPhases = unfilteredPhases.map(phase => {
      const phaseStartDate = parseDate(phase.startingDate! as unknown as string)!
      const phaseEndDate = parseDate(phase.endDate! as unknown as string)!

      if (phase.parentLineNo >= form.parentLineNo) {
        return {
          ...phase,
          startingDate: phaseStartDate.plus(dateChange).toString(),
          endDate: phaseEndDate.plus(dateChange).toString(),
          __typename: undefined,
        }
      } else if (phaseEndDate.equals(startDate).toString()) {
        return {
          ...phase,
          endDate: phaseEndDate.plus(dateChange).toString(),
          __typename: undefined,
        }
      } else {
        return { ...phase, __typename: undefined }
      }
    })

    const result = await updateJobProductionPhases({
      variables: { jobId: +jobId, jobProductionPhases: changedPhases },
      refetchQueries: [JOB_WITH_PHASES],
    })

    if (result.data) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }
    handleClose()
  }

  return (
    <>
      <Formik.Formik initialValues={initialValues} onSubmit={handleSubmitChangeStartDate}>
        <DialogFormik minWidth='xs' title={t(`EDIT_STARTDATE_PHASE`)} onClose={handleClose} open={open}>
          <MutationErrorMessage mutation={updateJobProductionPhasesState} />
          <Section>
            <MFlexBlock vertical padding={[6, 4, 7, 4]}>
              <MFlexItem alignSelf='center'>
                <MText block textVariant='inherit' textColor={MTextColor.dark}>
                  {phaseText}
                </MText>
              </MFlexItem>
              <MFlexItem>
                <Formik.Field
                  component={MFieldConnector}
                  name={nameof<EditJobPhaseForm>(`startDate`)}
                  label={t(`START_DATE`)}
                >
                  <FieldDate minWidth='xs' dialogHeader={t(`EDIT_STARTDATE_PHASE`)} />
                </Formik.Field>
              </MFlexItem>
            </MFlexBlock>
          </Section>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
