import styled from '@emotion/styled'
import { Dialog, IconButton, Slide, useMediaQuery, useTheme } from '@mui/material'
import { Breakpoint } from '@mui/material'
import { MColor, MText, MTextColor, spacing } from '@mprise/react-ui'
import React, { ReactNode } from 'react'
import { Button } from './Button'
import { MaterialIcon } from './Icon'
import { mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'

/* Copied from mprise/react-ui to adjust css */

export interface DialogProps {
  minWidth?: number | Breakpoint
  open: boolean
  title: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  onClose: React.MouseEventHandler
  onSubmit: React.FormEventHandler
  onReset?: React.FormEventHandler
}

export const DialogWithBackButton = (dialogProps: DialogProps) => (
  <DialogWithHeader dialogProps={dialogProps}>
    <MDialogHeaderWithClose onClose={dialogProps.onClose}>{dialogProps.title}</MDialogHeaderWithClose>
  </DialogWithHeader>
)

export const DialogWithSubmitButton = (dialogProps: DialogProps) => (
  <DialogWithHeader dialogProps={dialogProps}>
    <MDialogHeaderWithSubmit onSubmit={dialogProps.onSubmit}>{dialogProps.title}</MDialogHeaderWithSubmit>
  </DialogWithHeader>
)

export const DialogWithoutButton = (dialogProps: DialogProps) => (
  <DialogWithHeader dialogProps={dialogProps}>
    <MDialogHeader>{dialogProps.title}</MDialogHeader>
  </DialogWithHeader>
)

const DialogWithHeader = ({
  children: headerElement,
  dialogProps,
}: {
  children: ReactNode
  dialogProps: DialogProps
}) => {
  const { minWidth, open, footer, children: dialogContent, onClose, onSubmit, onReset } = dialogProps
  return (
    <MDialogDialog minWidth={minWidth} open={open} onClose={onClose}>
      <MDialogForm onSubmit={onSubmit} onReset={onReset}>
        {headerElement}
        <MDialogContent>{dialogContent}</MDialogContent>
        <MDialogFooter>{footer}</MDialogFooter>
      </MDialogForm>
    </MDialogDialog>
  )
}

const MDialogDialog = ({
  minWidth = `xl`,
  open,
  children,
  onClose,
}: {
  minWidth?: number | Breakpoint
  open: boolean
  children: React.ReactNode
  onClose: React.MouseEventHandler
}) => {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down(minWidth))
  return (
    <Dialog fullScreen={breakpoint} open={open} onClose={onClose} TransitionComponent={Slide}>
      {children}
    </Dialog>
  )
}

const MDialogHeaderWithClose = ({
  children,
  onClose,
}: {
  children: React.ReactNode
  onClose: React.MouseEventHandler
}) => {
  return (
    <MDialogHeaderContainer>
      <DialogBackButton onClick={onClose} />
      <MDialogHeaderContent>
        <MText block textVariant='header slim' textColor={MTextColor.header} style={{ marginLeft: '-48px' }}>
          {children}
        </MText>
      </MDialogHeaderContent>
    </MDialogHeaderContainer>
  )
}

const MDialogHeaderWithSubmit = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode
  onSubmit: React.MouseEventHandler
}) => {
  return (
    <MDialogHeaderContainer>
      <MDialogHeaderContent>
        <MText block textVariant='header slim' textColor={MTextColor.header}>
          {children}
        </MText>
      </MDialogHeaderContent>
      <DialogSubmitButton onClick={onSubmit} />
    </MDialogHeaderContainer>
  )
}

const MDialogHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <MDialogHeaderContainer>
      <MDialogHeaderContent>
        <MText block textVariant='header slim' textColor={MTextColor.header}>
          {children}
        </MText>
      </MDialogHeaderContent>
    </MDialogHeaderContainer>
  )
}

const DialogBackButton = ({ onClick }: { onClick: React.MouseEventHandler }) => (
  <Button dataCy={''} color={`#009a44`} icon={<MaterialIcon value='arrow_back' />} onClick={onClick} />
)

const DialogSubmitButton = ({ onClick }: { onClick: React.MouseEventHandler }) => (
  <IconButton
    type='button'
    style={{ color: MColor.primary, border: `1px solid ${MColor.primary}`, width: `3rem`, height: `3rem` }}
    onClick={onClick}
  >
    <Icon path={mdiCheck} size={1} />
  </IconButton>
)

/**
 * Note: Prevents default and stops propagation of submit and reset events.
 */
const MDialogForm = ({
  children,
  onSubmit,
  onReset,
}: {
  children: React.ReactNode
  onSubmit: React.FormEventHandler
  onReset?: React.FormEventHandler
}) => {
  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit(e)
  }
  const handleReset = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    onReset?.(e)
  }
  return (
    <StyledForm action='#' onSubmit={handleSubmit} onReset={handleReset}>
      {children}
    </StyledForm>
  )
}
const StyledForm = styled.form`
  display: flex;
  display: contents;
  flex-grow: 1;
  flex-direction: column;
`

const MDialogHeaderContainer = styled.div`
  box-sizing: border-box;
  background-color: ${MColor.white};
  border-bottom: 4px solid ${MColor.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;
  padding: ${spacing(2)};
`

const MDialogHeaderContent = styled.div`
  box-sizing: border-box;
  text-align: center;
  align-self: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${spacing([0, 2])};
`

const MDialogContent = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  background-color: ${MColor.white};
  position: relative;
  padding: ${spacing(2)};
`

const MDialogFooter = styled.div`
  box-sizing: border-box;
  background-color: ${MColor.paper};
  position: relative;
  position: sticky;
  bottom: 0;
  padding: ${spacing([3, 2])};
`
