import { gql } from '@apollo/client'

export const CHANGE_ACTUAL_QUANTITY = gql`
  mutation changeActualQuantity($workItemId: Int!, $actualQuantity: Float!) {
    changeActualQuantity(workItemId: $workItemId, actualQuantity: $actualQuantity) {
      id
      actualQuantity
      type
    }
  }
`
