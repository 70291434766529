import { useEffect, useRef, useState } from 'react'
import { Formik, MFieldConnector, Yup } from '@mprise/react-ui'
import { Section } from '../../components/Section'
import { FieldJob } from '../../shared/form/FieldJob'
import { nameof } from '../../shared/typescript'
import { useTranslation } from 'react-i18next'
import { PhaseEntryForm } from './Home'
import { FieldPhase } from '../../shared/form/FieldPhase'
import { FieldJobInventoryDetail } from '../../shared/form/FieldJobInventoryDetail'
import { ValidationFriendlyWarning } from '../../shared/form/ValidationFriendlyWarning'
import { FieldPosition } from '../../shared/form/FieldPosition'
import { SelectedJobInventoryDetails, SelectedPhaseDetails } from '../../shared/form/selectionDetails'
import { useBeforeUnload } from '../../shared/before-unload'
import { FieldSelectionQuantity } from '../../shared/form/SelectionFieldQuantity'

export const PhaseForm = () => {
  const { t } = useTranslation()
  const fc = Formik.useFormikContext<PhaseEntryForm>()

  useBeforeUnload((!!fc.values.quantity || !!fc.values.phase?.id) && !fc.isSubmitting)

  const maxQuantity = fc.values.originalQuantity ?? 0
  const exceedsMaxQuantity = fc.values.quantity ? fc.values.quantity > maxQuantity : false
  const hasSelectedJid = fc.values.jobInventoryDetail ? true : false
  const taskUnitOfMeasure = fc.values.jobInventoryDetail?.unitOfMeasure
  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  return (
    <>
      <Section>
        <div style={{ padding: '0.5rem' }}>
          <Formik.Field component={MFieldConnector} name={nameof<PhaseEntryForm>('job')}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<PhaseEntryForm>('fromPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`FROM_POSITION`} neverDisable />
          </Formik.Field>
          <div style={{ display: 'none' }}>
            <Formik.Field component={MFieldConnector} name={nameof<PhaseEntryForm>('jobInventoryDetail')}>
              <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
            </Formik.Field>
          </div>
          <Formik.Field component={MFieldConnector} name={nameof<PhaseEntryForm>('phase')}>
            <FieldPhase
              title={t('ASSIGN_PHASE')}
              phase={fc.values.phase}
              jobInventoryDetail={fc.values.jobInventoryDetail}
            />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<PhaseEntryForm>('quantity')}>
            <FieldSelectionQuantity unitOfMeasure={taskUnitOfMeasure} />
            <ValidationFriendlyWarning
              open={exceedsMaxQuantity && hasSelectedJid}
              text={t(`WARNING_EXCEEDS_QUANTITY`)}
            />
          </Formik.Field>
        </div>
      </Section>
      <div style={{ marginTop: '1rem' }}>
        <Section>
          <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={false} />
          <SelectedPhaseDetails phase={fc.values.phase} />
        </Section>
      </div>
    </>
  )
}

PhaseForm.useSchema = () => {
  const { t } = useTranslation()
  const [schema] = useState(
    (): Yup.SchemaOf<PhaseEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required()
          .nullable()
          .label(t(`JOB`)),
        fromPosition: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .nullable(),
        jobInventoryDetail: Yup.mixed().required().label(t(`JOB_INVENTORY_DETAIL`)),
        phase: Yup.mixed()
          .test(`phase`, t('NOTIFICATION_PHASE_INVALID'), (input, context) => {
            if (
              input &&
              input.productionPhase &&
              context.parent.jobInventoryDetail &&
              context.parent.jobInventoryDetail.productionPhase
            ) {
              return input.productionPhase !== context.parent.jobInventoryDetail.productionPhase
            } else return true
          })
          .required()
          .label(t(`PHASE`)),
        originalQuantity: Yup.number().nullable().defined().label('originalQuantity'),
        quantity: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue && typeof originalValue === 'string') {
              const processedValue = originalValue.replace(',', '.')
              return parseFloat(processedValue)
            }
            return value
          })
          .min(0.5)
          .required()
          .label(t(`QUANTITY`))
          .typeError('Quantity is a required field'),
        workItemId: Yup.mixed().optional().nullable(),
      }),
  )

  return schema
}
