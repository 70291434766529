import { SectionList, MenuSection } from '../components/Section'
import { Toolbar } from '../components/Header'
import { MAppsButton } from '../components/AppsButton'
import { useTranslation } from 'react-i18next'
import { RoleVisible } from '../auth'
import { CollapseWrapper } from '../components/CollapseWrapper'
import { useAppSettingsContext } from '../context/AppSettingsContext'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { QueryErrorMessage } from '../shared/apollo'
import { LoadingSwitchPanel } from '../shared/loading-switch-panel'
import { Helmet } from 'react-helmet'
import { SelectResourceDialog } from '../shared/dialog/SelectResourceDialog'
import { GET_APPS } from '../gql/apps'
import { useLazyQuery, useQuery } from '@apollo/client'
import { defined } from '../shared/typescript'
import { GET_COMPANIES } from '../gql/companies'
import { MpriseAuthProvider } from '@mprise/react-auth'

export const MenuRoute = () => {
  const { t } = useTranslation()

  const { user } = MpriseAuthProvider.useContext()
  const roles = user?.roles
  const hasTransferCarriersRole = roles?.includes('GREENHOUSE_TRANSFER_CARRIERS')

  const { company: currentCompany, setCompany, resource } = useAppSettingsContext()

  let redirect = useNavigate()
  const [initialRender, setInitialRender] = useState(true)
  const [getCompanies, { data: companies }] = useLazyQuery(GET_COMPANIES, {
    onError: console.error,
  })

  useEffect(() => {
    if (!currentCompany) {
      getCompanies()
    }
  }, [])

  if (companies) {
    if (!(companies.companies.length === 1) && !currentCompany) {
      if (!initialRender) {
        redirect('/settings/company')
      } else if (initialRender && companies.companies.length > 0) {
        redirect('/settings/company')
      }
    }
  }

  useEffect(() => {
    if (companies && companies.companies.length && initialRender && companies.companies.length === 1) {
      const companyName = companies.companies[0]?.name!
      const companyId = companies.companies[0]?.id!
      setCompany({ name: companyName, id: companyId })
      setInitialRender(false)
    }
    if (companies && initialRender && companies.companies.length > 1) {
      localStorage.removeItem('__DEFAULT_COMPANY_GH_MS')
      setInitialRender(false)
    }
  }, [companies])

  const appsQuery = useQuery(GET_APPS)
  const apps = appsQuery.data?.myApplications?.filter(defined).map((app: any) => ({ ...app, url: app.publicUrl })) ?? []

  return (
    <>
      <Helmet title={t('AGRIWARE_GREENHOUSE')} />
      <LoadingSwitchPanel query={appsQuery}>
        <QueryErrorMessage query={appsQuery} />
        <Toolbar forward={<MAppsButton apps={apps} />} />
        <SectionList>
          <CollapseWrapper isOpened={!!currentCompany}>
            <RoleVisible role='GREENHOUSE_MY_TASKS'>
              <MenuSection to='/tasks/my' iconName='pending_actions' title={t('MY_TASKS')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_JOB'>
              <MenuSection to='/job' iconName='format_list_bulleted' title={t('JOB')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_JOB_MUTATIONS'>
              <MenuSection to='/job-mutations' iconName='low_priority' title={t('JOB_MUTATIONS')} />
            </RoleVisible>
            {hasTransferCarriersRole ? (
              <RoleVisible role='GREENHOUSE_TRANSFER_CARRIERS'>
                <MenuSection to='/transfer' iconName='low_priority' title={t('TRANSFER')} />
              </RoleVisible>
            ) : (
              <RoleVisible role='GREENHOUSE_TRANSFER'>
                <MenuSection to='/transfer/job' iconName='low_priority' title={t('TRANSFER')} />
              </RoleVisible>
            )}

            <RoleVisible role='GREENHOUSE_AREA_REGISTRATION'>
              <MenuSection to='/area-registration' iconName='map' title={t('AREA_REGISTRATION')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_PHASE'>
              <MenuSection to='/phase' iconName='arrow_right_alt' title={t('PHASE')} />
            </RoleVisible>
            <RoleVisible role='GREENHOUSE_SCRAP'>
              <MenuSection to='/scrap' iconName='delete_forever' title={t('SCRAP')} />
            </RoleVisible>
          </CollapseWrapper>
          <MenuSection to='/settings' iconName='settings' title={t('TITLE_SETTINGS')} />
        </SectionList>
        {!resource?.id && !!currentCompany && <SelectResourceDialog />}
      </LoadingSwitchPanel>
    </>
  )
}
