import React from 'react'
import styled from '@emotion/styled'
import { Dialog, IconButton, Slide, useMediaQuery, useTheme } from '@mui/material'
import { Breakpoint } from '@mui/material'
import { mdiCheck } from '@mdi/js'
import Icon from '@mdi/react'
import { MColor, spacing } from '@mprise/react-ui'
import { Button } from '../components/Button'
import { MaterialIcon } from '../components/Icon'
import { useFormikContext } from 'formik'

/* Copied from mprise/react-ui to adjust css */

export interface MDialogProps {
  minWidth?: number | Breakpoint
  open: boolean
  title: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
  onClose: React.MouseEventHandler
  onSubmit: React.FormEventHandler
  onReset?: React.FormEventHandler
}

export const MDialog = ({ minWidth, open, title, footer, children, onClose, onSubmit, onReset }: MDialogProps) => (
  <MDialogDialog minWidth={minWidth} open={open} onClose={onClose}>
    <MDialogForm onSubmit={onSubmit} onReset={onReset}>
      <MDialogHeader onClose={onClose}>{title}</MDialogHeader>
      <MDialogContent>{children}</MDialogContent>
      <MDialogFooter>{footer}</MDialogFooter>
    </MDialogForm>
  </MDialogDialog>
)

export const MDialogContentText = ({ children }: { children: React.ReactNode }) => {
  return <div className='gh-dialog-content-text'>{children}</div>
}

const MDialogDialog = ({
  minWidth = 'xs',
  open,
  children,
  onClose,
}: {
  minWidth?: number | Breakpoint
  open: boolean
  children: React.ReactNode
  onClose: React.MouseEventHandler
}) => {
  const theme = useTheme()
  const breakpoint = useMediaQuery(theme.breakpoints.down(minWidth))
  return (
    <Dialog fullScreen={breakpoint} open={open} onClose={onClose} TransitionComponent={Slide}>
      {children}
    </Dialog>
  )
}

const MDialogHeader = ({ children, onClose }: { children: React.ReactNode; onClose: React.MouseEventHandler }) => {
  const handleClose = (e: React.MouseEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    onClose(e)
  }
  const fc = useFormikContext()
  return (
    <MDialogHeaderContainer>
      <DialogBackButton onClick={handleClose} disabled={fc?.isSubmitting} />
      <MDialogHeaderContent>
        <div className='gh-dialog-header-title'>{children}</div>
      </MDialogHeaderContent>
      <MDialogSubmitButton disabled={fc?.isSubmitting} />
    </MDialogHeaderContainer>
  )
}

const DialogBackButton = ({ onClick, disabled }: { onClick: React.MouseEventHandler; disabled?: boolean }) => (
  <Button
    dataCy={''}
    color={MColor.primary}
    icon={<MaterialIcon value='arrow_back' />}
    onClick={onClick}
    disabled={disabled}
  />
)

const MDialogSubmitButton = ({ disabled }: { disabled?: boolean }) => (
  <IconButton
    type='submit'
    style={{ color: MColor.primary, border: `1px solid ${MColor.primary}`, width: '3rem', height: '3rem' }}
    disabled={disabled}
  >
    <Icon path={mdiCheck} size={1} />
  </IconButton>
)

/**
 * Note: Prevents default and stops propagation of submit and reset events.
 */
const MDialogForm = ({
  children,
  onSubmit,
  onReset,
}: {
  children: React.ReactNode
  onSubmit: React.FormEventHandler
  onReset?: React.FormEventHandler
}) => {
  const handleSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    onSubmit(e)
  }
  const handleReset = (e: React.FormEvent<Element>) => {
    e.preventDefault()
    e.stopPropagation()
    onReset?.(e)
  }
  return (
    <StyledForm action='#' onSubmit={handleSubmit} onReset={handleReset}>
      {children}
    </StyledForm>
  )
}
const StyledForm = styled.form`
  display: flex;
  display: contents;
  flex-grow: 1;
  flex-direction: column;
`

const MDialogHeaderContainer = styled.div`
  box-sizing: border-box;
  background-color: ${MColor.white};
  border-bottom: 4px solid ${MColor.primary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;
  padding: ${spacing(2)};
`

const MDialogHeaderContent = styled.div`
  box-sizing: border-box;
  text-align: center;
  align-self: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${spacing([0, 2])};
`

const MDialogContent = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  background-color: #eeeeee;
  position: relative;
`

const MDialogFooter = styled.div`
  box-sizing: border-box;
  background-color: ${MColor.paper};
  position: relative;
  position: sticky;
  bottom: 0;
`
