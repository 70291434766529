import React, { useEffect, useRef } from 'react'
import { Formik, MAudio, MColor, MField, MFieldConnector, MFlexBlock, MText } from '@mprise/react-ui'
import { TFunction, useTranslation } from 'react-i18next'
import { CreateInventoryFormDefinition, ScannedLot } from './Home'
import { Section } from '../../../components/Section'
import { defined, nameof } from '../../../shared/typescript'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { useParams } from 'react-router-dom'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { Button, CircularProgress, Divider } from '@mui/material'
import IconAdd from '@mui/icons-material/Add'
import { Card } from '../../../components/Card'
import { FieldLotNumber } from '../../../shared/form/FieldLotNumber'
import { parseFloatQuantity, formatQuantity, formatUnitOfMeasure } from '../../../shared/formats'
import { useBeforeUnload } from '../../../shared/before-unload'
import { useQuery } from '@apollo/client'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { FieldDecimalQuantity } from '../../../shared/form/FieldDecimalQuantity'
import { NumberFormatItem, useAppSettingsContext } from '../../../context/AppSettingsContext'
import { Lot } from '../../../shared/interfaces'
import { Maybe, WorkItemTemplateTaskOption } from '../../../shared/enums'
import { FieldQuantity } from '../../../shared/form/FieldQuantity'
import { ValidationFriendlyWarning } from '../../../shared/form/ValidationFriendlyWarning'
import { InlineTable } from '../../../shared/section/InlineTable'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { i18n } from '../../../i18n/instance'

const emptyValue = {
  id: 0,
  itemId: '',
  itemName: '',
  plannedVariantCode: '',
  quantity: 0,
  plannedQuantity: 0,
  reportedQuantity: 0,
  lotNumbersRequired: false,
  activeLot: null,
  scannedLots: null,
  plannedLotNumbers: [],
  reportedLotNumbers: [],
}

export const CreateInventoryForm = ({ blockAlreadyHandled }: { blockAlreadyHandled: boolean }) => {
  const fc = Formik.useFormikContext<CreateInventoryFormDefinition>()
  const taskId = useParams().taskId ?? ''
  const { numberFormat } = useAppSettingsContext()

  useBeforeUnload(!blockAlreadyHandled && formIsDirty(fc) && !fc.isSubmitting)

  const { data, loading } = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })
  const task = data?.nworkTask
  const allowDecimalQuantity = task?.workItem?.unitOfMeasure === 'Containers'

  const allowOverruleLots = fc.values.taskOptions.includes(WorkItemTemplateTaskOption.AllowOverruleLotReservationGh)
  const itemConsumptionsWithLot = fc.values.reportedItemConsumptions?.filter(i => i.lotNumbersRequired) ?? []
  const itemConsumptionsWithoutLot = fc.values.reportedItemConsumptions?.filter(i => !i.lotNumbersRequired) ?? []

  /** Autofill the Item Consumption quantities based on the Lot Item Consumptions (if available). */
  useEffect(
    () => {
      if (fc.values.taskOptions.includes(WorkItemTemplateTaskOption.AutoFillConsumptionQuantityGh)) {
        if (itemConsumptionsWithLot.length > 0) {
          const currentQuantityInForm = itemConsumptionsWithLot
            .flatMap(ic =>
              ic.scannedLots?.length
                ? ic.scannedLots.map(s => s.quantity)
                : [parseFloatQuantity(ic.quantity, numberFormat) ?? 0],
            )
            .reduce((a, b) => a + b, 0)
          const plannedTotal = itemConsumptionsWithLot.reduce((a, b) => a + b.plannedQuantity, 0)
          const ratio = currentQuantityInForm / plannedTotal

          autoFillItemConsumptionsWithoutLot(ratio)
          autoFillJobInventoryPutAway(ratio)
        }
      }
    },
    // prettier-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: should only trigger when one of these specific values changes.
    [itemConsumptionsWithLot.reduce((sum, ic) => sum + (ic.quantity ?? 0), 0), itemConsumptionsWithLot.flatMap(ic => ic.scannedLots ?? [])?.reduce((sum, lot) => sum + (lot.quantity ?? 0), 0)],
  )

  /** Autofill the Item Consumption quantities based on the PutAway value (when no Lot Item Consumptions are available). */
  useEffect(() => {
    if (fc.values.taskOptions.includes(WorkItemTemplateTaskOption.AutoFillConsumptionQuantityGh)) {
      if (!itemConsumptionsWithLot?.length && fc.values.plannedQuantityPutAway && fc.values.quantityPutAway) {
        const currentQuantityInForm = parseFloatQuantity(fc.values.quantityPutAway!, numberFormat)
        const plannedTotal = parseFloatQuantity(fc.values.plannedQuantityPutAway!, numberFormat)
        const ratio = currentQuantityInForm / plannedTotal

        autoFillItemConsumptionsWithoutLot(ratio)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: should only trigger when this specific value changes.
  }, [fc.values.quantityPutAway])

  /** Prefill the planned quantity value in putAway and Item Consumption. */
  useEffect(() => {
    if ((itemConsumptionsWithoutLot?.length || itemConsumptionsWithLot?.length) && fc.values.plannedQuantityPutAway) {
      if (fc.values.quantityPutAway === null) {
        preFillItemConsumptionsWithoutLot()
        preFillJobInventoryPutAway()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: should only trigger when this specific value changes.
  }, [fc.values.plannedQuantityPutAway])

  function autoFillItemConsumptionsWithoutLot(ratio: number) {
    const updatedItemConsumptions = fc.values.reportedItemConsumptions.map((ic: any) => {
      if (ic.lotNumbersRequired) {
        return ic
      }
      const updatedQuantity = ic.plannedQuantity * ratio
      const quantity = allowDecimalQuantity ? formatQuantity(updatedQuantity, numberFormat) : Math.ceil(updatedQuantity)
      return {
        ...ic,
        quantity: quantity,
      }
    })

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: updatedItemConsumptions,
      dirty: true,
    }))
  }
  function preFillItemConsumptionsWithoutLot() {
    const updatedItemConsumptions = fc.values.reportedItemConsumptions.map((ic: any) => {
      if (ic.lotNumbersRequired) {
        return ic
      }
      const updatedQuantity = ic.plannedQuantity - ic.reportedQuantity
      const quantity = allowDecimalQuantity ? formatQuantity(updatedQuantity, numberFormat) : Math.ceil(updatedQuantity)
      return {
        ...ic,
        quantity: quantity,
      }
    })

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: updatedItemConsumptions,
      dirty: true,
    }))
  }

  function autoFillJobInventoryPutAway(ratio: number) {
    const plannedQuantityPutAway = parseFloatQuantity(fc.values.plannedQuantityPutAway!, numberFormat)
    fc.setFieldValue('quantityPutAway', Math.ceil(plannedQuantityPutAway * ratio))
  }
  function preFillJobInventoryPutAway() {
    const plannedQuantityPutAway = parseFloatQuantity(fc.values.plannedQuantityPutAway!, numberFormat)
    const reportedQuantityPutAway = parseFloatQuantity(fc.values.reportedQuantityPutAway!, numberFormat)
    fc.setFieldValue('quantityPutAway', Math.ceil(plannedQuantityPutAway - reportedQuantityPutAway))
  }

  function handleChangeNumber(itemConsumptionId: number, newValue?: Maybe<number>) {
    const index = fc.values.reportedItemConsumptions.findIndex(i => i.id === itemConsumptionId)
    if (index < 0) {
      return
    }
    const value = fc.values.reportedItemConsumptions[index] ?? emptyValue
    value.quantity = newValue
    const newValues = [...fc.values.reportedItemConsumptions]
    newValues[index] = value

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: newValues,
      dirty: true,
    }))
  }

  function handleChangeLot(itemConsumptionId: number, newValue?: Maybe<Lot>) {
    const index = fc.values.reportedItemConsumptions.findIndex(i => i.id === itemConsumptionId)
    if (index < 0) {
      return
    }
    const value = fc.values.reportedItemConsumptions[index] ?? emptyValue

    if (newValue) {
      value.activeLot = { lotId: newValue.id, lotNumber: newValue.lotNumber ?? '' }
    } else {
      value.activeLot = null
    }
    value.quantity = null
    const newValues = [...fc.values.reportedItemConsumptions]
    newValues[index] = value

    fc.setValues(current => ({
      ...current,
      reportedItemConsumptions: newValues,
      dirty: true,
    }))
  }

  if (loading) {
    return (
      <MFlexBlock justifyContent='center' padding={10}>
        <CircularProgress />
      </MFlexBlock>
    )
  }

  return (
    <>
      {task && <TaskDetailsSection task={task} />}

      {itemConsumptionsWithLot.length > 0 && (
        <ItemConsumptionsWithLotCard
          itemConsumptions={itemConsumptionsWithLot}
          allowOverruleLots={allowOverruleLots}
          allowDecimalQuantity={allowDecimalQuantity}
          numberFormat={numberFormat}
          handleChangeLot={handleChangeLot}
          handleChangeNumber={handleChangeNumber}
        />
      )}

      <JobInventoryPutAwayCard
        plannedQuantity={fc.values.plannedQuantityPutAway ?? 0}
        reportedQuantity={fc.values.reportedQuantityPutAway ?? 0}
        quantityUnit={formatUnitOfMeasure(fc.values.quantityUnitPutAway)}
        currentQuantity={fc.values.quantityPutAway}
        allowDecimalQuantity={allowDecimalQuantity}
        numberFormat={numberFormat}
      />

      {itemConsumptionsWithoutLot.length > 0 && (
        <ItemConsumptionsWithoutLotCard
          itemConsumptions={itemConsumptionsWithoutLot}
          allowDecimalQuantity={allowDecimalQuantity}
          numberFormat={numberFormat}
          handleChangeNumber={handleChangeNumber}
        />
      )}
    </>
  )
}

const JobInventoryPutAwayCard = ({
  plannedQuantity,
  reportedQuantity,
  quantityUnit,
  currentQuantity,
  allowDecimalQuantity,
  numberFormat,
}: {
  plannedQuantity: number
  reportedQuantity: number
  quantityUnit: string
  currentQuantity: Maybe<number>
  allowDecimalQuantity: boolean
  numberFormat: NumberFormatItem
}) => {
  const { t } = useTranslation()

  const maxPutAwayQuantity = plannedQuantity - reportedQuantity

  return (
    <Section>
      <Card>
        <div>{t(`WorkResultType.JOB_INVENTORY_PUTAWAY`)}</div>
        <Divider style={{ marginTop: '5px', marginBottom: '20px' }} />
        <Formik.Field component={MFieldConnector} name={nameof<CreateInventoryFormDefinition>('quantityPutAway')}>
          {allowDecimalQuantity ? (
            <FieldDecimalQuantity
              title={`${t(`QUANTITY`)}  (${formatQuantity(reportedQuantity, numberFormat)} / ${formatQuantity(
                plannedQuantity,
                numberFormat,
              )}  ${quantityUnit})`}
              max={maxPutAwayQuantity}
            />
          ) : (
            <FieldQuantity
              title={`${t(`QUANTITY`)}  (${reportedQuantity} / ${plannedQuantity}  ${quantityUnit})`}
              max={maxPutAwayQuantity}
            />
          )}
          <ValidationFriendlyWarning
            open={currentQuantity ? parseFloatQuantity(currentQuantity, numberFormat) > maxPutAwayQuantity : false}
            text={t(`WARNING_EXCEEDS_PLANNED_QUANTITY`)}
          />
        </Formik.Field>
        <Formik.Field component={MFieldConnector} name={nameof<CreateInventoryFormDefinition>('toPositionPutAway')}>
          <FieldPosition title='' label={`POSITION`} neverDisable />
        </Formik.Field>
      </Card>
    </Section>
  )
}

const ItemConsumptionsWithLotCard = ({
  itemConsumptions,
  allowOverruleLots,
  allowDecimalQuantity,
  numberFormat,
  handleChangeLot,
  handleChangeNumber,
}: {
  itemConsumptions: CreateInventoryFormDefinition['reportedItemConsumptions']
  allowOverruleLots: boolean
  allowDecimalQuantity: boolean
  numberFormat: NumberFormatItem
  handleChangeLot: (itemConsumptionId: number, newValue?: Maybe<Lot>) => void
  handleChangeNumber: (itemConsumptionId: number, newValue?: Maybe<number>) => void
}) => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<CreateInventoryFormDefinition>()
  const alerts = FlashAlerts.useAlert()

  const lotFieldRef = useRef<HTMLInputElement>(null)

  return (
    <Section>
      <Card>
        {itemConsumptions.map(value => {
          const unitOfMeasure = formatUnitOfMeasure(value.quantityUnit)
          const selectedLot = value.activeLot

          const plannedLots = value.plannedLotNumbers ?? []
          const plannedLotNumbers = plannedLots.map(lot => lot.lotNumber)
          const plannedLot = selectedLot && plannedLots.find(lot => lot.lotNumber === selectedLot.lotNumber)
          const plannedQuantityLot = plannedLot?.quantity ?? value.plannedQuantity

          let reportedQtyForLot = value.reportedLotNumbers
            .filter(lot => !plannedLot || lot.lotNumber === selectedLot.lotNumber)
            .reduce((a, b) => a + b.quantity, 0)
          if (value.scannedLots?.length) {
            if (plannedLot) {
              reportedQtyForLot += value.scannedLots.find(s => s.lotNumber === plannedLot.lotNumber)?.quantity ?? 0
            } else {
              reportedQtyForLot += value.scannedLots.reduce((a, b) => a + b.quantity, 0)
            }
          }

          const exceedsQuantity =
            value.quantity && plannedQuantityLot
              ? reportedQtyForLot + parseFloatQuantity(value.quantity, numberFormat) > plannedQuantityLot
              : false

          const submitCurrentActiveLot = () => {
            const activeLot = value.activeLot
            const activeQuantity = parseFloatQuantity(value.quantity, numberFormat)
            if (activeLot && activeQuantity) {
              upsertScannedLotEntry({ id: activeLot.lotId, lotNumber: activeLot.lotNumber, quantity: activeQuantity })
              lotFieldRef.current?.focus()
              handleChangeLot(value.id, undefined)
              handleChangeNumber(value.id, undefined)
            }
          }

          if (value.activeLot && (value.quantity === null || value.quantity === undefined)) {
            const remainQtyForLot = value.reportedLotNumbers
              .filter(lot => plannedLot || lot.lotNumber === selectedLot?.lotNumber)
              .reduce((a, b) => a + b.quantity, 0)
            value.quantity = plannedQuantityLot - remainQtyForLot
          }

          const upsertScannedLotEntry = (input: ScannedLot) => {
            const itemConsumptionToUpdate = fc.values.reportedItemConsumptions?.find(i => i.id === value.id)
            if (!itemConsumptionToUpdate) {
              return
            }
            itemConsumptionToUpdate.scannedLots = [
              ...(itemConsumptionToUpdate.scannedLots?.filter(
                i => i.id !== input.id || i.lotNumber !== input.lotNumber,
              ) ?? []),
              input,
            ]
            const updatedItemConsumptions = [
              ...fc.values.reportedItemConsumptions.filter(i => i.id !== value.id),
              itemConsumptionToUpdate,
            ]
            MAudio.scanSuccess()
            fc.setValues({ ...fc.values, reportedItemConsumptions: updatedItemConsumptions })
          }

          const deleteScannedLotEntry = (id: number | string) => {
            const recordToUpdate = fc.values.reportedItemConsumptions?.find(i => i.id === value.id)
            if (!recordToUpdate) {
              return
            }
            recordToUpdate.scannedLots = recordToUpdate.scannedLots?.filter(i => i.id !== id) ?? []
            const updatedItemConsumptions = [
              ...fc.values.reportedItemConsumptions.filter(i => i.id !== value.id),
              recordToUpdate,
            ]
            fc.setValues({ ...fc.values, reportedItemConsumptions: updatedItemConsumptions })

            MAudio.scanSuccess()
            alerts.push(t('ENTRY_REMOVED'), 'success')
          }

          return (
            <React.Fragment key={value.id}>
              <div>{t('WorkResultType.ITEM_CONSUMPTION_LOT')}</div>
              <Divider style={{ marginTop: '5px', marginBottom: '20px' }} />
              <MText block textVariant='small' style={{ marginLeft: '0.5rem', marginBottom: '-1rem' }}>
                {value.itemName}
              </MText>
              <MField
                vertical
                name=''
                label=''
                value={value.activeLot as unknown as Lot}
                onChange={newValue => handleChangeLot(value.id, newValue)}
              >
                <FieldLotNumber
                  title={t(`ASSIGN_LOT`)}
                  label={t(`Lot`)}
                  itemId={value.itemId}
                  plannedVariantCode={value.plannedVariantCode}
                  allowOverruleLots={allowOverruleLots}
                  plannedLotNumbers={plannedLotNumbers}
                  ref={lotFieldRef}
                />
              </MField>
              <MField
                vertical
                name=''
                label=''
                value={value.quantity}
                onChange={newValue => handleChangeNumber(value.id, newValue)}
              >
                <MFlexBlock justifyContent='space-between'>
                  <div>
                    {allowDecimalQuantity ? (
                      <FieldDecimalQuantity
                        title={`${t('QUANTITY')} (${formatQuantity(reportedQtyForLot, numberFormat)} / ${formatQuantity(
                          plannedQuantityLot,
                          numberFormat,
                        )} ${unitOfMeasure})`}
                      />
                    ) : (
                      <FieldQuantity
                        title={`${t('QUANTITY')} (${reportedQtyForLot} / ${plannedQuantityLot} ${unitOfMeasure})`}
                      />
                    )}
                    <ValidationFriendlyWarning
                      open={exceedsQuantity}
                      text={t(`WARNING_EXCEEDS_PLANNED_QUANTITY`)}
                      color={allowOverruleLots ? MColor.medium : MColor.high}
                    />
                  </div>
                  <Button
                    style={{ alignSelf: 'end', borderRadius: 100, marginBottom: '-14px' }}
                    endIcon={<IconAdd />}
                    onClick={submitCurrentActiveLot}
                    disabled={(exceedsQuantity && !allowOverruleLots) || !(value.activeLot && value.quantity)}
                  >
                    Submit
                  </Button>
                </MFlexBlock>
              </MField>
              {!!value.scannedLots?.length && (
                <InlineTable
                  columns={[
                    { label: t('LOT'), propertyName: 'lotNumber' },
                    { label: t('QUANTITY'), propertyName: 'quantity', isNumber: true },
                  ]}
                  values={value.scannedLots ?? []}
                  unitOfMeasure={value.quantityUnit ?? ''}
                  updateEntry={upsertScannedLotEntry}
                  deleteEntry={deleteScannedLotEntry}
                />
              )}
              <Divider style={{ marginTop: '25px', marginBottom: '10px' }} />
            </React.Fragment>
          )
        })}
      </Card>
    </Section>
  )
}

const ItemConsumptionsWithoutLotCard = ({
  itemConsumptions,
  allowDecimalQuantity,
  numberFormat,
  handleChangeNumber,
}: {
  itemConsumptions: CreateInventoryFormDefinition['reportedItemConsumptions']
  allowDecimalQuantity: boolean
  numberFormat: NumberFormatItem
  handleChangeNumber: (itemConsumptionId: number, newValue?: Maybe<number>) => void
}) => {
  const { t } = useTranslation()

  return (
    <Section>
      <Card>
        <div>{t('WorkResultType.ITEM_CONSUMPTION')}</div>
        <Divider style={{ marginTop: '5px', marginBottom: '20px' }} />
        {itemConsumptions.map(value => {
          const unitOfMeasure = formatUnitOfMeasure(value.quantityUnit)
          const maxQuantity = value.plannedQuantity ? value.plannedQuantity - (value.reportedQuantity ?? 0) : 0
          const exceedsMax = value.quantity ? parseFloatQuantity(value.quantity, numberFormat) > maxQuantity : false

          return (
            <React.Fragment key={value.id}>
              <MField
                vertical
                name=''
                label=''
                value={value.quantity}
                onChange={newValue => handleChangeNumber(value.id, newValue)}
              >
                {allowDecimalQuantity ? (
                  <FieldDecimalQuantity
                    title={`${value.itemName} (${formatQuantity(
                      value.reportedQuantity,
                      numberFormat,
                    )} / ${formatQuantity(value.plannedQuantity, numberFormat)} ${unitOfMeasure})`}
                  />
                ) : (
                  <FieldQuantity
                    title={`${value.itemName} (${value.reportedQuantity} / ${value.plannedQuantity} ${unitOfMeasure})`}
                  />
                )}
                <ValidationFriendlyWarning open={exceedsMax} text={t('WARNING_EXCEEDS_PLANNED_QUANTITY')} />
              </MField>
              <Divider style={{ marginTop: '25px', marginBottom: '10px' }} />
            </React.Fragment>
          )
        })}
      </Card>
    </Section>
  )
}

CreateInventoryForm.validate = (values: CreateInventoryFormDefinition, t: TFunction<'translation', undefined>) => {
  const reportedItemConsumptionErrors = []
  const allowOverruleLots = values.taskOptions.includes(WorkItemTemplateTaskOption.AllowOverruleLotReservationGh)

  for (const element of values.reportedItemConsumptions) {
    if (element?.lotNumbersRequired) {
      if (element.quantity && !element.activeLot) {
        reportedItemConsumptionErrors.push(`Select a lot for '${element.itemName}'`)
      } else if (!element.quantity && element.activeLot) {
        reportedItemConsumptionErrors.push(`Add a quantity for '${element.itemName}'`)
      } else if (element.scannedLots?.length && element.activeLot) {
        reportedItemConsumptionErrors.push(t('LOT_NOT_SUBMITTED', { lotNumber: element.activeLot.lotNumber }))
      } else if (!allowOverruleLots) {
        const activeInputLot = element.activeLot ? { ...element.activeLot, quantity: +(element.quantity ?? 0) } : null
        const inputLots = (element.scannedLots?.length ? element.scannedLots : [activeInputLot]).filter(defined)

        if (element.plannedLotNumbers.length) {
          for (const inputLot of inputLots) {
            const plannedLot = element.plannedLotNumbers.find((lot: any) => lot.lotNumber === inputLot.lotNumber)

            if (!plannedLot) {
              reportedItemConsumptionErrors.push(t('LOT_NOT_ASSIGNED'))
              continue
            }

            const reportedQtyForLot = element.reportedLotNumbers
              .filter((lot: any) => lot.lotNumber === inputLot.lotNumber)
              .reduce((a: any, b: any) => a + b.quantity, 0)

            if (reportedQtyForLot + inputLot.quantity > plannedLot.quantity) {
              reportedItemConsumptionErrors.push(t('ALLOCATED_LOT_QUANTITY_EXCEEDED'))
            }
          }
        } else {
          const reportedBefore = element.reportedLotNumbers.reduce((a: any, b: any) => a + b.quantity, 0)
          const reportedInCurrentForm = inputLots.reduce((a: any, b: any) => a + b.quantity, 0)

          if (reportedBefore + reportedInCurrentForm > element.plannedQuantity) {
            reportedItemConsumptionErrors.push(t('WARNING_EXCEEDS_PLANNED_QUANTITY'))
          }
        }
      }
    }
  }

  if (thisSubmitWillAutoClose(values)) {
    const validationError = validateLotRequirements(values)
    if (validationError) {
      reportedItemConsumptionErrors.push(validationError)
    }
  }

  if (!values.quantityPutAway && !values.reportedItemConsumptions.some(c => c?.quantity || c.scannedLots?.length)) {
    reportedItemConsumptionErrors.push('Add a quantity for at least one field')
  }

  const errors: any = {}
  if (reportedItemConsumptionErrors?.length) {
    errors.reportedItemConsumptions = reportedItemConsumptionErrors
  }

  const reportedJobInventoryPutAwayErrors = []
  if (values.quantityPutAway && !values.toPositionPutAway?.id) {
    reportedJobInventoryPutAwayErrors.push('Please select a position')
  }

  if (reportedJobInventoryPutAwayErrors?.length) {
    errors.reportedJobInventoryPutAway = reportedJobInventoryPutAwayErrors
  }

  return errors
}

const thisSubmitWillAutoClose = (form: CreateInventoryFormDefinition) => {
  const autoStopOption = form.taskOptions.find(x => x.startsWith('AUTO_STOP_PERC_'))

  if (autoStopOption) {
    const autoCompletePercStr = autoStopOption.replace('AUTO_STOP_PERC_', '').replace('_GH', '')
    const autoCompletePerc = parseInt(autoCompletePercStr)

    const totalPlannedPutAway = form.plannedQuantityPutAway
    if (totalPlannedPutAway && !Number.isNaN(autoCompletePerc)) {
      const previouslyReportedPutAway = form.reportedQuantityPutAway ?? 0
      const currentReportedPutAway = form.quantityPutAway ?? 0
      const autoCompleteQty = totalPlannedPutAway * (autoCompletePerc / 100)
      if (previouslyReportedPutAway + currentReportedPutAway >= autoCompleteQty) {
        return true
      }
    }
  }
}

/**
 * Validates Lot requirements when a task is about to be finished.
 * In case no Lots were pre-planned for this ItemConsumption, OR the overrule taskOption is on: at least one Lot must be reported.
 * Otherwise, when the overrule option is off, the exact planned Lots must also be reported.
 * @link Also see TaskItem.validateLotRequirements, which contains the same check but for manual closing of work items.
 */
const validateLotRequirements = (form: CreateInventoryFormDefinition) => {
  const overruleAllowed = form.taskOptions.includes(WorkItemTemplateTaskOption.AllowOverruleLotReservationGh)
  const itemConsumptionsWithLot = form.reportedItemConsumptions?.filter(i => i.lotNumbersRequired) ?? []

  let error = ''

  for (const itemConsumption of itemConsumptionsWithLot ?? []) {
    const plannedLots = itemConsumption.plannedLotNumbers
    const previouslyReportedLots = itemConsumption.reportedLotNumbers
    const activeInputLotNumber = itemConsumption.activeLot?.lotNumber
    const currentReportedLotNumbers = (
      itemConsumption.scannedLots?.length ? itemConsumption.scannedLots.map(x => x.lotNumber) : [activeInputLotNumber]
    ).filter(defined)
    if (!plannedLots?.length || overruleAllowed) {
      if (!previouslyReportedLots?.length && !currentReportedLotNumbers?.length) {
        error = `${i18n.t('WORK_ITEM_NOT_COMPLETE_FOR_AUTO_CLOSE')} ${i18n.t('AT_LEAST_ONE_REPORTED_LOT_REQUIRED', {
          itemName: itemConsumption.itemName,
        })}`
      }
    } else {
      const plannedLotNumbers: string[] = plannedLots.map((x: any) => x.lotNumber)
      const reportedLotNumbers: string[] = [
        ...previouslyReportedLots.map((x: any) => x.lotNumber),
        ...currentReportedLotNumbers,
      ]
      const missingLotNumbers = plannedLotNumbers.filter(x => !reportedLotNumbers.includes(x))
      if (missingLotNumbers?.length) {
        error = `${i18n.t('WORK_ITEM_NOT_COMPLETE_FOR_AUTO_CLOSE')} ${i18n.t('PLANNED_LOTS_REQUIRED_TO_REPORT', {
          itemName: itemConsumption.itemName,
          lotNumbers: missingLotNumbers.join(', '),
        })}`
      }
    }
  }

  return error
}

export const formIsDirty = (fc: Formik.FormikContextType<CreateInventoryFormDefinition>) => {
  return (
    !!fc.values.quantityPutAway ||
    fc.values.reportedItemConsumptions.some(x => !!x.activeLot || !!+(x.quantity ?? 0) || !!x.scannedLots?.length)
  )
}
