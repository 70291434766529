import { DateTimeFormatter, LocalDate } from '@js-joda/core'
import { i18n } from '../i18n/instance'
import { Maybe } from './enums'
import { NumberFormatItem } from '../context/AppSettingsContext'

const dateFormatter = DateTimeFormatter.ofPattern('yyyy-MM-dd')

export const formatDateWithWeekNo = (date?: string | LocalDate) => {
  if (!date) {
    return ''
  }

  const localDate = typeof date === 'string' ? LocalDate.parse(date.substring(0, 10), dateFormatter) : date

  const weekNo = localDate?.isoWeekOfWeekyear()
  return `${localDate?.format(dateFormatter)} ${weekNo ? `wk: ${weekNo}` : ''}`
}

export const dateToWeekNo = (date?: string | LocalDate) => {
  if (!date) {
    return ''
  }

  const localDate = typeof date === 'string' ? LocalDate.parse(date.substring(0, 10), dateFormatter) : date

  return localDate?.isoWeekOfWeekyear()
}

export const formatUnitOfMeasure = (unitOfMeasure?: string | null) => {
  if (!unitOfMeasure) {
    return ''
  }
  switch (unitOfMeasure) {
    case 'Production Units of Measure':
    case 'PCS':
    case 'Productie-eenheden': //Dutch value added as a temporary workaround (BC is saving already localized values)
    case 'STUK': //Dutch value added as a temporary workaround (BC is saving already localized values)
      return i18n.t('PRODUCTION_UNITS_OF_MEASURE')
    case 'Containers':
      return i18n.t('CONTAINERS')
    case 'Pots':
      return i18n.t('Pots')
    default:
      return unitOfMeasure
  }
}

/** Formats a number into a localized string (with decimal and thousands separators) according to the given NumberFormat. */
export const formatQuantity = (quantity: Maybe<number> | undefined, numberFormat: NumberFormatItem) => {
  if (!quantity) {
    return '0'
  }
  return numberFormat.id === 'DECIMAL_COMMA'
    ? quantity.toLocaleString('nl-NL', {})
    : quantity.toLocaleString(undefined, { style: 'decimal' })
}

export const parseFloatQuantity = (
  quantity: Maybe<number | string> | undefined,
  numberFormat: NumberFormatItem,
): number => {
  if (!quantity) {
    return 0
  }
  let parsedQuantity

  if (numberFormat?.id === 'DECIMAL_COMMA') {
    // Handle cases like 1,5 to 1.5
    const numberWithPeriod = quantity.toString().replace('.', '').replace(',', '.')
    parsedQuantity = parseFloat(numberWithPeriod)
  } else {
    // Handle cases like 1.000 to 1000
    parsedQuantity = parseFloat(quantity.toString().replace(',', ''))
  }

  return isNaN(parsedQuantity) ? 0 : parsedQuantity
}
