import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { MField } from '@mprise/react-ui'
import { FieldDecimalQuantity } from './FieldDecimalQuantity'
import { FieldPosition } from './FieldPosition'
import { FieldQuantity } from './FieldQuantity'
import { Maybe } from '../enums'
import { FieldPercentage } from './FieldPercentage'
import { FieldTitle } from '../../components/FieldTitle'
import { formatUnitOfMeasure } from '../formats'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { JobInventoryDetail, JobProductionPhase, Position } from '../interfaces'

export type SortingFormEntry = {
  sortingCode: string
  quantity: Maybe<number>
  toPosition: Maybe<Position>
}

export type SortingWithPerc = {
  sortingCode: string
  quantity: Maybe<number>
  percentage: Maybe<number>
  toPosition: Maybe<Position>
}

export const FieldComboSortToPosition = ({
  selectedJid,
  selectedPhase,
  sortingEntries,
  unitOfMeasure,
  maxQuantity,
  setSortingEntries,
}: {
  selectedJid: Maybe<JobInventoryDetail> | undefined
  selectedPhase: Maybe<JobProductionPhase> | undefined
  sortingEntries: Array<SortingFormEntry>
  unitOfMeasure: string
  maxQuantity: Maybe<number>
  setSortingEntries: (sortingEntries: Array<SortingFormEntry>) => void
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { defaultNumberInputMethod, showPositionWhileSorting } = useAppSettingsContext()
  const initialValues = sortingEntries.map(x => ({ ...x, percentage: null }) as SortingWithPerc)

  const [sortingsState, setSortingsState] = useState<SortingWithPerc[]>(initialValues)
  const [isRegisteringInPercentage, setIsRegisteringInPercentage] = useState(defaultNumberInputMethod === 'PERCENTAGE')

  useEffect(() => {
    setSortingsState(
      sortingEntries.map(x => {
        return { ...x, percentage: x.quantity && maxQuantity ? Math.floor((x.quantity / maxQuantity) * 100) : null }
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Justification: should NOT run whenever the sortingEntries change; only when selected Jid or Phase changes.
  }, [selectedJid, selectedPhase])

  function handleChangeQuantity(newQuantity: number, index: number) {
    const entry = sortingsState[index]
    if (!entry) {
      return
    }

    entry.quantity = newQuantity
    if (maxQuantity) {
      entry.percentage = Math.floor((newQuantity / maxQuantity) * 100)
    }
    const updated = [...sortingsState]
    updated[index] = entry

    handleSetSortingEntries(updated)
  }

  function handleChangePosition(newPosition: any, index: number) {
    const entry = sortingsState[index]
    if (!entry) {
      return
    }
    if (newPosition) {
      entry.toPosition = { id: newPosition.id, name: newPosition.name, code: newPosition.code }
    } else {
      entry.toPosition = { id: '', name: '', code: '' }
    }

    const updated = [...sortingsState]
    updated[index] = entry

    handleSetSortingEntries(updated)
  }

  const handlePercentageToggle = () => {
    if (maxQuantity) {
      const hasQuantityInput = sortingsState.reduce((a: any, b: any) => a + b.quantity, 0)
      if (!isRegisteringInPercentage && hasQuantityInput) {
        const updated = sortingsState.map(x => {
          if (x.quantity) {
            return { ...x, percentage: Math.floor((x.quantity / maxQuantity) * 100) }
          } else return x
        })
        setSortingsState(updated)
        const withoutPerc = updated.map(x => {
          return { ...x, percentage: undefined } as SortingFormEntry
        })
        setSortingEntries(withoutPerc)
      }
    }
    setIsRegisteringInPercentage(!isRegisteringInPercentage)
  }

  const handlePercentageChange = (newPercentage: number, index: number) => {
    const entry = sortingsState[index]
    if (!entry || !maxQuantity) {
      return
    }
    entry.percentage = newPercentage
    const newQuantity = (maxQuantity * newPercentage) / 100
    const formattedQuantity = unitOfMeasure === 'Containers' ? newQuantity : Math.ceil(newQuantity)

    entry.quantity = formattedQuantity

    const updated = [...sortingsState]
    updated[index] = entry

    handleSetSortingEntries(updated)
  }

  function handleSetSortingEntries(entries: SortingWithPerc[]) {
    setSortingsState(entries)

    const withoutPerc = entries.map(x => {
      return { sortingCode: x.sortingCode, quantity: x.quantity, toPosition: x.toPosition } as SortingFormEntry
    })
    setSortingEntries(withoutPerc)
  }

  return (
    <>
      {sortingsState.map((value: any, i: number) => (
        <React.Fragment key={i}>
          {!isRegisteringInPercentage ? (
            <MField
              vertical
              name=''
              label=''
              value={value.quantity}
              onChange={newQuantity => handleChangeQuantity(newQuantity, i)}
            >
              {unitOfMeasure === 'Containers' ? (
                <FieldDecimalQuantity
                  title={`${t('SORTING')} ${value.sortingCode} ${unitOfMeasure ? `(${value.percentage ?? 0}%)` : ''}`}
                />
              ) : (
                <FieldQuantity
                  title={`${t('SORTING')} ${value.sortingCode} ${unitOfMeasure ? `(${value.percentage ?? 0}%)` : ''}`}
                />
              )}
            </MField>
          ) : (
            <MField
              vertical
              name=''
              label=''
              value={value.percentage}
              onChange={(newPercentage: number) => handlePercentageChange(newPercentage, i)}
            >
              <FieldTitle
                title={`${t('SORTING')} ${value.sortingCode} ${
                  unitOfMeasure ? `(${value.quantity ?? 0} ${formatUnitOfMeasure(unitOfMeasure)})` : ''
                }`}
              />
              <div className='gh-field-quantity'>
                <FieldPercentage />
              </div>
            </MField>
          )}
          {showPositionWhileSorting && (
            <MField
              vertical
              name=''
              label=''
              value={value.toPosition}
              onChange={newPosition => handleChangePosition(newPosition, i)}
            >
              <FieldPosition title={t('TO_POSITION')} label={'TO_POSITION'} neverDisable />
            </MField>
          )}
          <Divider style={{ marginTop: '15px', marginBottom: '5px' }} />
        </React.Fragment>
      ))}
      <div className={classes.underLinedText} onClick={handlePercentageToggle}>
        {isRegisteringInPercentage ? t('REGISTER_QUANTITY') : t('REGISTER_PERCENTAGE')}
      </div>
    </>
  )
}

const useStyles = makeStyles(() => ({
  underLinedText: {
    color: 'black',
    fontSize: '14px',
    fontWeight: 200,
    padding: '0.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'grey',
    },
    textDecoration: 'underline',
  },
}))
