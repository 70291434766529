import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyQueryHookOptions, OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Formik, MAudio, MColor, MFieldConnector, MFlex, MFlexBlock, Yup } from '@mprise/react-ui'
import { TaskDetailsSection } from '../../shared/form/TaskDetailsSection'
import { FieldJob } from '../../shared/form/FieldJob'
import { FieldPosition } from '../../shared/form/FieldPosition'
import { FieldJobInventoryDetail } from '../../shared/form/FieldJobInventoryDetail'
import { FieldQuantity } from '../../shared/form/FieldQuantity'
import { fail, nameof } from '../../shared/typescript'
import { SelectedJobInventoryDetails } from '../../shared/form/selectionDetails'
import { Section } from '../../components/Section'
import { AreaRegistrationEntryForm, partialEmptyFormAAR } from './Home'
import { GET_AREA_REGISTRATION_TASKS } from '../../gql/query/workItems/getAreaRegistrationTasks'
import { FlashAlerts } from '../../shared/flash-alerts'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { useHistory } from '../../shared/use-history'
import { mdiPlay } from '@mdi/js'
import Icon from '@mdi/react'
import { useParams } from 'react-router-dom'
import { JOB_BY_CODE } from '../../gql/query/jobs/jobByCode'
import { START_TASK } from '../../gql/mutation/statusChange/statusChange'
import { FieldTitle } from '../../components/FieldTitle'
import { WorkItemTemplateTaskOption, WorkStatus } from '../../shared/enums'
import { START_TIME_REG } from '../../gql/mutation/timeReg'
import { OUTBOUND_BY_WORKITEM_ID } from '../../gql/query/outboundByWorkItemId'
import { POSITIONS_BY_FILTER } from '../../gql/positions'
import { FieldSpacingMethod } from '../../shared/form/FieldSpacingMethod'

export const AreaRegistrationForm = () => {
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const h = useHistory()

  const fc = Formik.useFormikContext<AreaRegistrationEntryForm>()
  const appSettings = useAppSettingsContext()
  const companyId = appSettings.company?.id ?? h.push('/')
  const currentResource = appSettings.resource ?? h.push('/')
  const jobCode = useParams().jobCode

  const [canSelectJid, setCanSelectJid] = useState(false)
  const [showSpacingMethodField, setShowSpacingMethodField] = useState(true)

  const [getJobByCode] = useLazyQuery(JOB_BY_CODE)
  const [getAreaRegistrationTasks, { data: areaRegTasksData }] = useLazyQuery(GET_AREA_REGISTRATION_TASKS)
  const [getSubPositions] = useLazyQuery(POSITIONS_BY_FILTER)
  const [getOutboundJid] = useLazyQuery(OUTBOUND_BY_WORKITEM_ID, {
    fetchPolicy: `network-only`,
  })
  const [startTaskMutation] = useMutation(START_TASK)
  const [startTimeReg] = useMutation(START_TIME_REG)

  const jobFieldRef = useRef<HTMLInputElement>(null)
  const positionFieldRef = useRef<HTMLInputElement>(null)

  /** Set focus to the correct field when the form opens, or after a submit. */
  useEffect(() => {
    if (positionFieldRef.current?.value) {
      jobFieldRef?.current?.focus()
    } else {
      positionFieldRef?.current?.focus()
    }
  }, [positionFieldRef.current?.value, jobFieldRef.current?.value])

  useEffect(() => {
    // Set Job if jobCode is in the page params and remove param from url
    const selectedJob = fc.values.job?.code

    if (jobCode && !selectedJob) {
      getJobByCode({
        variables: {
          filter: {
            companyId: +companyId,
            code: jobCode,
            applyDataPermissions: true,
          },
        },
      }).then(x => {
        const job = x.data?.job
        fc.setFieldValue('job', { id: job.id, name: job.name, code: job.code })
        h.push('/area-registration')
      })
    }
  }, [])

  useEffect(() => {
    // get AAR Task or clear form if job has changed.
    const selectedJobCode = fc.values.job?.code === '' ? null : fc.values.job?.code
    const selectedJid = fc.values.jobInventoryDetail
    const selectedTask = fc.values.task

    const hasChangedJob =
      (selectedJid && selectedJobCode !== selectedJid?.job.code) ||
      (selectedTask && selectedTask.workItem?.jobs[0]?.code !== selectedJobCode)

    if (!selectedJobCode || hasChangedJob) {
      const job = fc.values.job ?? { id: '', name: '', code: '' }
      fc.setValues(current => ({ ...current, ...partialEmptyFormAAR, job: job }))
      setCanSelectJid(false)
    }

    if (selectedJobCode) {
      getAreaRegistrationTasks({
        variables: {
          jobCode: selectedJobCode,
        },
        fetchPolicy: 'no-cache',
      })
    }
  }, [fc.values.job?.code])

  useEffect(() => {
    // handle if task is found or not found (ad-hoc)
    if (!areaRegTasksData) {
      return
    }

    const tasks = areaRegTasksData.getAreaRegistrationTasks
    const hasAARTask = tasks?.length
    const selectedJobCode = fc.values.job?.code
    const selectedTask = fc.values.task

    if (selectedJobCode) {
      if (hasAARTask) {
        const task = tasks[0]

        if (!selectedTask || selectedTask.id !== tasks.id) {
          const workItem = task.workItem

          const plannedSpacingMethod = workItem?.jobInventoryPutAway[0]?.planned?.spacingMethod
          const spacingMethod = plannedSpacingMethod?.id
            ? {
                id: +plannedSpacingMethod.id,
                description: plannedSpacingMethod.description,
                code: plannedSpacingMethod.code,
              }
            : { id: null, description: '', code: '' }

          fc.setValues(current => ({
            ...current,
            workItemId: workItem.id,
            spacingMethod: spacingMethod,
            task: task,
            phaseCode: workItem.phaseCode,
            isWorkItemTransfer: true,
          }))

          setCanSelectJid(true)
          setQuantityForTask(task, workItem, fc, getOutboundJid)
          startTask(task, workItem, currentResource, startTaskMutation, startTimeReg)
          if (!spacingMethod?.id) {
            setShowSpacingMethodField(false)
          }

          if (tasks.length > 1) {
            MAudio.scanError()
            alerts.push(t(`MULTIPLE_TASKS_FIRST_SELECTED`), 'error')
          }
        }
      } else {
        // No AAR task found => Ad hoc
        setCanSelectJid(true)
        setShowSpacingMethodField(true)
      }
    }
  }, [areaRegTasksData])

  useEffect(() => {
    // prefill quantity to that available on jid
    const selectedJid = fc.values.jobInventoryDetail
    const prefilledQuantity = fc.values.quantity ?? 0

    if (selectedJid) {
      if (!prefilledQuantity || selectedJid.remainingQuantity < prefilledQuantity) {
        fc.setFieldValue('quantity', selectedJid.remainingQuantity)
      }
    }
  }, [fc.values.jobInventoryDetail])

  // Get sub positions
  useEffect(() => {
    if (fc.values.toPosition?.code) {
      const parentCode = fc.values.toPosition.code
      getSubPositions({
        variables: {
          filter: {
            companyId: +companyId,
            parentCode: parentCode,
          },
        },
      }).then(x => {
        const subPosQ = (x.data.positions?.page ?? []).map((position: any, i: number) => ({
          ...position,
          sub: position.code.replace(`${parentCode}-`, ''),
          index: i,
        }))

        if (subPosQ.length) {
          const isEqual = areSubPositionsEqual(subPosQ, fc.values.subPositions)

          if (!isEqual) {
            fc.setFieldValue('subPositions', subPosQ)
            const newSubPosition = subPosQ[0]
            if (newSubPosition.code !== fc.values.toSubPosition?.code) {
              fc.setFieldValue('toSubPosition', subPosQ[0])
              fc.setFieldValue('pillar', newSubPosition.startPillar)
            }
          }
        } else {
          MAudio.scanError()
          alerts.push(t(`NO_SUB_POSITIONS`), 'error')
          clearSubAndPillar()
        }
      })
    } else {
      clearSubAndPillar()
    }
  }, [fc.values.toPosition?.code])

  const handleLeftClick = () => {
    const fromIndex = fc.values.toSubPosition?.index
    if (fromIndex) {
      fc.setFieldValue('toSubPosition', fc.values.subPositions[fromIndex - 1])
    }
  }

  const handleRightClick = () => {
    const fromIndex = fc.values.toSubPosition?.index ?? 0
    if (fromIndex < fc.values.subPositions.length - 1) {
      fc.setFieldValue('toSubPosition', fc.values.subPositions[fromIndex + 1])
    }
  }

  const handleUpClick = (e: any) => {
    const endPillar = fc.values.toSubPosition?.endPillar ?? 0
    if (fc.values.pillar != null) {
      if (fc.values.pillar < endPillar) {
        fc.setFieldValue('pillar', fc.values.pillar + 1)
      }
    }
  }

  const handleDownClick = (e: any) => {
    const startPillar = fc.values.toSubPosition?.startPillar ?? 0
    if (fc.values.pillar != null) {
      if (fc.values.pillar > startPillar) {
        fc.setFieldValue('pillar', fc.values.pillar - 1)
      }
    }
  }

  // when sub changes and selected pillar falls outside the pillar-range of that sub, change pillar
  useEffect(() => {
    if (
      fc.values.toSubPosition &&
      fc.values.pillar &&
      (fc.values.pillar < fc.values.toSubPosition.startPillar || fc.values.pillar > fc.values.toSubPosition.endPillar)
    ) {
      fc.setFieldValue('pillar', fc.values.toSubPosition.startPillar)
    }
  }, [fc.values.toSubPosition])

  // handle manual input of pillar field
  function handleChangePillar(event: any) {
    const newPillar = +event.target.value
    const startPillar = fc.values.toSubPosition?.startPillar
    const endPillar = fc.values.toSubPosition?.endPillar

    if (Number.isInteger(newPillar) && startPillar && endPillar && newPillar >= startPillar && newPillar <= endPillar) {
      fc.setFieldValue('pillar', newPillar)
    } else {
      alerts.push(t(`PILLAR_OUT_OF_RANGE`), 'error')
    }
  }

  function clearSubAndPillar() {
    fc.setFieldValue('subPositions', [])
    fc.setFieldValue('toSubPosition', null)
    fc.setFieldValue('pillar', null)
  }

  const classes = useStyles()

  return (
    <>
      {fc.values.task && (
        <TaskDetailsSection task={fc.values.task} reportedQuantityOverride={fc.values.reportedQuantity} />
      )}
      <Section>
        <div style={{ padding: '0.5rem' }}>
          <Formik.Field component={MFieldConnector} name={nameof<AreaRegistrationEntryForm>('job')} color={'#6a707e'}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
          {canSelectJid && (
            <div style={{ display: 'none' }}>
              <Formik.Field component={MFieldConnector} name={nameof<AreaRegistrationEntryForm>('jobInventoryDetail')}>
                <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
              </Formik.Field>
            </div>
          )}
          <Formik.Field component={MFieldConnector} name={nameof<AreaRegistrationEntryForm>('toPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`TO_POSITION`} neverDisable ref={positionFieldRef} />
          </Formik.Field>

          <MFlexBlock justifyContent='flex-end'>
            <MFlexBlock vertical justifyContent='space-between' style={{ margin: '2rem 2rem 0 0' }}>
              <MFlex>
                <MFlex justifyContent='space-between' alignItems='end' style={{ width: '14rem' }}>
                  <MFlex vertical>
                    <FieldTitle title={t(`SUB_POSITION`)} />
                    <input
                      value={fc.values.toSubPosition?.sub ?? ''}
                      type='text'
                      readOnly
                      size={5}
                      className={classes.inputField}
                    />
                  </MFlex>
                  <MFlex justifyContent='space-between' style={{ width: '6.5rem' }}>
                    <IconButton
                      onClick={handleLeftClick}
                      disabled={fc.values.toSubPosition?.index === 0}
                      className={classes.playButton}
                    >
                      <Icon path={mdiPlay} size={1} rotate={180} />
                    </IconButton>
                    <IconButton
                      onClick={handleRightClick}
                      disabled={(fc.values.toSubPosition?.index ?? 0) === (fc.values.subPositions?.length ?? 0) - 1}
                      className={classes.playButton}
                    >
                      <Icon path={mdiPlay} size={1} />
                    </IconButton>
                  </MFlex>
                </MFlex>
              </MFlex>

              <MFlex vertical style={{ width: '12rem', margin: '2rem 0' }}>
                <MFlex justifyContent='space-between'>
                  <MFlex vertical alignSelf='center' style={{ marginTop: '-1.5rem' }}>
                    <FieldTitle title={t(`PILLAR`)} />
                    <input
                      value={fc.values.pillar ?? ''}
                      type='numeric'
                      pattern='^[0-9]+$'
                      size={5}
                      className={classes.inputField}
                      onChange={newValue => handleChangePillar(newValue)}
                    />
                  </MFlex>
                  <MFlex vertical justifyContent='space-between' style={{ height: '6.5rem' }}>
                    <IconButton
                      onClick={handleUpClick}
                      disabled={fc.values.toSubPosition?.endPillar === fc.values.pillar}
                      className={classes.playButton}
                    >
                      <Icon path={mdiPlay} size={1} rotate={270} />
                    </IconButton>
                    <IconButton
                      onClick={handleDownClick}
                      disabled={fc.values.toSubPosition?.startPillar === fc.values.pillar}
                      className={classes.playButton}
                    >
                      <Icon path={mdiPlay} size={1} rotate={90} />
                    </IconButton>
                  </MFlex>
                </MFlex>
              </MFlex>
            </MFlexBlock>
          </MFlexBlock>
          {showSpacingMethodField && (
            <Formik.Field component={MFieldConnector} name={nameof<AreaRegistrationEntryForm>('spacingMethod')}>
              <FieldSpacingMethod />
            </Formik.Field>
          )}
          <Formik.Field component={MFieldConnector} name={nameof<AreaRegistrationEntryForm>('quantity')}>
            <FieldQuantity disabled={false} />
          </Formik.Field>
        </div>
      </Section>
      <div style={{ marginTop: '1rem' }}>
        <Section>
          <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={false} />
        </Section>
      </div>
    </>
  )
}

AreaRegistrationForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<AreaRegistrationEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required('Job is a required field')
          .nullable()
          .label(t(`JOB`)),
        jobInventoryDetail: Yup.mixed().required().label(t(`JOB_INVENTORY_DETAIL`)),
        toPosition: Yup.object()
          .shape({
            id: Yup.string().required('Position is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required()
          .nullable()
          .label(t(`POSITION`)),
        toSubPosition: Yup.object()
          .shape({
            id: Yup.string().required('SubPosition is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required()
          .nullable()
          .label(t(`SUB_POSITION`)),
        pillar: Yup.number()
          .required('Pillar is a required field')
          .typeError('Pillar is a required field')
          .test('pillar', t('PILLAR_OUT_OF_RANGE'), (input: any, context: any) => {
            if (
              context.parent.toSubPosition &&
              (input < context.parent.toSubPosition.startPillar || input > context.parent.toSubPosition.endPillar)
            ) {
              return false
            } else return true
          }),
        spacingMethod: Yup.object()
          .shape({
            id: Yup.number().nullable(),
            description: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .test(`spacingMethod`, t('Spacing method is a required field'), (input, context) => {
            if (!context.parent.isWorkItemTransfer && !input.id) {
              return false
            } else return true
          })
          .required()
          .nullable()
          .label(t('SPACING_METHOD')),
        originalQuantity: Yup.number().nullable().defined().label('originalQuantity'),
        quantity: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue && typeof originalValue === 'string') {
              const processedValue = originalValue.replace(',', '.')
              return parseFloat(processedValue)
            }
            return value
          })
          .min(1)
          .required()
          .label(t(`QUANTITY`))
          .typeError('Quantity is a required field'),
        reportedQuantity: Yup.number().optional().nullable(),
        workItemId: Yup.string().nullable(),
        isWorkItemTransfer: Yup.boolean().required(),
        phaseCode: Yup.string().nullable(),
        task: Yup.object().nullable(),
        subPositions: Yup.mixed().optional(),
      }),
  )

  return schema
}

const useStyles = makeStyles(() => ({
  playButton: {
    backgroundColor: MColor.primary,
    color: MColor.paper,
    borderRadius: '0.2rem',
    width: '2.5rem',
    height: '2.5rem',
    padding: 0,
    '&:hover': {
      backgroundColor: MColor.primary,
      opacity: '0.8',
    },
    '&:disabled': {
      backgroundColor: MColor.header,
      opacity: '0.4',
    },
  },
  inputField: {
    textAlign: 'center',
    border: '1px solid #839ab4',
    borderRadius: '0.2rem',
    fontWeight: 300,
    height: '2rem',
    marginTop: '0.5rem',
  },
}))

function startTask(task: any, workItem: any, currentResource: any, startTaskMutation: any, startTimeReg: any) {
  if (task.status !== WorkStatus.Done) {
    if (!currentResource?.id) {
      fail('expects resource id')
    }

    startTaskMutation({
      variables: {
        workItemId: +workItem.id,
        taskId: +task.id,
        currentResourceId: +currentResource?.id,
      },
    }).then((response: { data: { startTask: any } }) => {
      const task = response.data.startTask
      startTimeReg({
        variables: {
          workItemId: task.workItem.cosmosKey,
          taskId: task.cosmosKey,
          resourceId: currentResource.cosmosKey,
        },
      })
    })
  }
}

function setQuantityForTask(
  task: any,
  workItem: any,
  fc: Formik.FormikContextType<AreaRegistrationEntryForm>,
  getReportedQuantity: {
    (
      options?: Partial<LazyQueryHookOptions<any, OperationVariables>> | undefined,
    ): Promise<QueryResult<any, OperationVariables>>
    (arg0: { variables: { workItemId: number } }): Promise<any>
  },
) {
  // If taskOption includes bypassJobInventory, use reportedQuantity from JobInventoryPutAway, otherwise from outbound.
  const taskOptions = task.taskOptions ?? []
  const bypassJobInventory = taskOptions.includes(WorkItemTemplateTaskOption.BypassJobInventoryGh)

  if (bypassJobInventory) {
    const reportedQuantity =
      task.workItem.jobInventoryPutAway[0].reported.reduce((a: any, b: any) => a + (b.quantity ?? 0), 0) ?? 0
    fc.setFieldValue('quantity', workItem.plannedQuantity - reportedQuantity)
    fc.setFieldValue('reportedQuantity', reportedQuantity)
  } else {
    getReportedQuantity({
      variables: {
        workItemId: +workItem.id!,
      },
    }).then(response => {
      const outbounds = response.data.outboundJobInventoryByWorkItemId ?? []
      const reportedQuantity = outbounds.reduce((x: any, y: any) => x + (y.quantity ?? 0), 0)
      fc.setFieldValue('quantity', workItem.plannedQuantity - reportedQuantity)
      fc.setFieldValue('reportedQuantity', reportedQuantity)
    })
  }
}

function areSubPositionsEqual(subPositionsQ: any[], formikSubPositions: any[]) {
  const subPositionsQIds = subPositionsQ.flatMap((x: any) => x.id) ?? []
  const subPositionsIds = formikSubPositions.flatMap((x: any) => x.id) ?? []

  return (
    subPositionsQIds.length === subPositionsIds.length &&
    subPositionsQIds.every((val: number, idx: number) => val === subPositionsIds[idx])
  )
}
