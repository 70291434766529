import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { DialogHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { useDebounceValue } from '../../shared/debounce-value'
import { DialogBackButton, DialogSaveButton } from '../../shared/dialog/DialogButtons'
import { MColor } from '@mprise/react-ui'
import { GET_RESOURCES } from '../../gql/resources'
import { useLazyQuery } from '@apollo/client'
import { QueryErrorMessage } from '../../shared/apollo'

export const SettingsResourceRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState(``)
  const debouncedSearch = useDebounceValue(search, 500)

  const [getResources, { error, data: resources, refetch, loading }] = useLazyQuery(GET_RESOURCES, {
    onError: console.error,
  })

  const { company, resource: currentResource, setResource } = useAppSettingsContext()
  const [selected, setSelected] = useState(currentResource)

  useEffect(() => {
    getResources({
      variables: {
        filter: {
          companyId: +company?.id!,
          type: 'Person',
          removed: false,
          blocked: false,
        },
        limit: 50,
        offset: 0,
      },
    })
  }, [])

  useEffect(() => {
    if (debouncedSearch) {
      getResources({
        variables: {
          filter: {
            companyId: +company?.id!,
            searchString: debouncedSearch,
            type: 'Person',
            blocked: false,
            removed: false,
          },
          limit: 50,
          offset: 0,
        },
      })
    } else {
      getResources({
        variables: {
          filter: {
            companyId: +company?.id!,
            type: 'Person',
            blocked: false,
            removed: false,
          },
          limit: 50,
          offset: 0,
        },
      })
    }
  }, [debouncedSearch])

  const handleCancel = () => {
    h.push('/settings')
  }
  const handleSave = () => {
    setResource(selected)
    h.push('/settings')
  }

  return (
    <>
      <DialogHeader
        title={t('TITLE_SETTINGS')}
        backward={<DialogBackButton onClick={handleCancel} />}
        forward={<DialogSaveButton onClick={handleSave} />}
      />
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_RESOURCE')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <List>
              {resources && resources.resources.page ? (
                resources.resources.page.map((resource: any, idx: number) => (
                  <ListItem
                    key={resource?.id ?? idx}
                    icon={<MaterialIcon value='person' style={{ fontSize: '2rem' }} />}
                    primary={resource?.name ?? resource?.name}
                    action={
                      selected?.id === resource?.id ? (
                        <MaterialIcon value='check' style={{ color: MColor.primary }} />
                      ) : null
                    }
                    onClick={() =>
                      resource &&
                      setSelected({
                        id: resource.id,
                        name: resource.name ?? '',
                        code: resource.code ?? '',
                        cosmosKey: resource.cosmosKey ?? '',
                      })
                    }
                  />
                ))
              ) : (
                <p>No resources found.</p>
              )}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
