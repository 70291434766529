import React from 'react'
import { Formik, MAudio, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../shared/use-history'
import { useLocalState } from '../../../shared/local-state'
import { TransferForm } from './TransferForm'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { ValidationIssues } from '../../../mprise-light/ValidationIssues'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import { useApolloClient, useMutation } from '@apollo/client'
import { REPORT_TRANSFER_TO_POSITION } from '../../../gql/mutation/reportTransferToPosition'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { parseFloatQuantity } from '../../../shared/formats'
import { Maybe, WorkItemTemplateTaskOption } from '../../../shared/enums'
import { JobInventoryDetail } from '../../../shared/interfaces'
import { fail } from '../../../shared/typescript'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'
import { BULK_REDUCE_JOB_INVENTORY } from '../../../gql/mutation/bulkReduceJobInventory'
import { REPORT_JOB_INVENTORY_PUTAWAY } from '../../../gql/mutation/reportJobInventoryPutAway'

export interface TransferEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string>; item?: Maybe<{ id: string; name?: Maybe<string> }> }>
  fromPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
  workItemCosmosKey: Maybe<string>
  taskCosmosKey: Maybe<string>
  task: any
}

export const TransferRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const schema = TransferForm.useSchema()
  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache
  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId ?? null
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)

  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const handleClose = async (autoFinished?: boolean, e?: React.FormEvent<Element>) => {
    e?.preventDefault()
    e?.stopPropagation()

    if (workItemId && taskId && !autoFinished) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
    }
    h.goBack()
  }

  const [reportTransferToPosition, reportTransferToPositionState] = useMutation(REPORT_TRANSFER_TO_POSITION)
  const [reportJobInventoryPutAway, reportJobInventoryPutAwayState] = useMutation(REPORT_JOB_INVENTORY_PUTAWAY)
  const [bulkReduceJobInventory, bulkReduceJobInventoryState] = useMutation(BULK_REDUCE_JOB_INVENTORY)

  const [initialValues] = useLocalState((): TransferEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      fromPosition: { id: '', name: '', code: '' },
      toPosition: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      originalQuantity: null,
      quantity: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
      workItemCosmosKey: null,
      taskCosmosKey: null,
      task: null,
    })
  }, [])

  const handleSubmit = async (form: TransferEntryForm, actions: Formik.FormikHelpers<TransferEntryForm>) => {
    actions.resetForm()
    let autoFinished = false
    let success = true

    if (!resourceId) {
      fail('expects resource id')
    }

    const originJobInventoryDetailId = form.jobInventoryDetail!.id
    const destinationPositionId = form.toPosition!.id
    const quantity = form.quantity!
    const formattedQuantity = parseFloatQuantity(quantity, numberFormat)
    const task = form.task
    const taskOptions = task?.taskOptions ?? []
    const bypassJobInventory = taskOptions.includes(WorkItemTemplateTaskOption.BypassJobInventoryGh)

    if (bypassJobInventory && workItemId && task) {
      const jobInventoryPutAway = task?.workItem.jobInventoryPutAway[0]
      const plannedJobInventoryPutAway = jobInventoryPutAway?.planned

      await reportJobInventoryPutAway({
        variables: {
          workItemId: +workItemId,
          taskId: +task.id,
          taskPutAwayId: +jobInventoryPutAway.id,
          values: {
            resourceId: resourceId,
            positionId: destinationPositionId,
            quantity: formattedQuantity,
            quantityUnit: form.jobInventoryDetail?.unitOfMeasure,
            quantityPerArea: plannedJobInventoryPutAway.quantityPerArea,
            quantityPerAreaUnit: plannedJobInventoryPutAway.quantityPerAreaUnit,
          },
        },
      }).then(response => {
        success &&= !!response.data
        autoFinished = response.data.registerJobInventoryPutAway.autoFinished

        bulkReduceJobInventory({
          variables: {
            jobInventoryIdsAndQuantities: [
              {
                jobInventoryDetailId: originJobInventoryDetailId,
                quantity: formattedQuantity,
              },
            ],
          },
        })
      })
    } else {
      await reportTransferToPosition({
        variables: {
          originJobInventoryDetailId: +originJobInventoryDetailId,
          destinationPositionId: +destinationPositionId,
          quantity: formattedQuantity,
          resourceId: +resourceId!,
          destinationPhase: phaseCode,
          workItemId: workItemId ? +workItemId : null,
        },
      }).then(response => {
        success &&= !!response.data
        autoFinished = response.data.transferPositionJobInventoryDetailGH.autoFinished
      })
    }
    if (success) {
      MAudio.scanSuccess()
      alerts.push(t('SUCCESS_MESSAGE'), `success`)
    }

    if (form.workItemCosmosKey && form.taskCosmosKey && autoFinished) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })

      stopTimeReg({
        variables: {
          workItemId: form.workItemCosmosKey,
          taskId: form.taskCosmosKey,
        },
      })
      handleClose(autoFinished)
    }
  }

  return (
    <>
      <Helmet title={t('TITLE_JOB_TO_POSITION')} />

      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
        <DialogFormik minWidth='xl' title={t('TRANSFER')} onClose={e => handleClose(false, e)} open={true}>
          <SavingSwitchPanel
            mutation={[
              reportTransferToPositionState,
              reportJobInventoryPutAwayState,
              bulkReduceJobInventoryState,
              stopTaskState,
            ]}
          >
            <ValidationIssues />
            <MutationErrorMessage
              mutation={[
                reportTransferToPositionState,
                reportJobInventoryPutAwayState,
                bulkReduceJobInventoryState,
                stopTaskState,
              ]}
            />
            <TransferForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
