import { useEffect, useRef, useState } from 'react'
import { Formik, MFieldConnector, Yup } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { TransferEntryForm } from './Home'
import { Section } from '../../../components/Section'
import { nameof } from '../../../shared/typescript'
import { FieldJob } from '../../../shared/form/FieldJob'
import { FieldJobInventoryDetail } from '../../../shared/form/FieldJobInventoryDetail'
import { FieldPosition } from '../../../shared/form/FieldPosition'
import { useParams } from 'react-router-dom'
import { SelectedJobInventoryDetails } from '../../../shared/form/selectionDetails'
import { useBeforeUnload } from '../../../shared/before-unload'
import { useQuery } from '@apollo/client'
import { WORKTASK_BY_ID_WITH_TASK_RESULTS } from '../../../gql/query/workItems/workTaskByIdWithTaskResults'
import { TaskDetailsSection } from '../../../shared/form/TaskDetailsSection'
import { FieldComboQuantityPerc } from '../../../shared/form/FieldComboQuantityPerc'

export const TransferForm = () => {
  const { t } = useTranslation()

  const fc = Formik.useFormikContext<TransferEntryForm>()
  const taskId = useParams().taskId ?? ''

  useBeforeUnload(!!fc.values.quantity && !fc.isSubmitting)

  // ********* MIGR TO DO: IMPLEMENT TASK *********

  const taskQuery = useQuery(WORKTASK_BY_ID_WITH_TASK_RESULTS, {
    variables: {
      where: [{ field: 'id', options: { eq: +taskId } }],
      filter: { mandatoryType: 'JOB_WORK_ORDER' },
    },
  })
  const task = taskQuery.data?.nworkTask

  useEffect(() => {
    // Prefill form if WorkItemTask is executed
    const job = (task?.workItem.jobs ?? [])[0]
    const toPosition = (task?.workItem.jobInventoryPutAway ?? [])[0]?.planned.position
    if (task && job && toPosition) {
      fc.setValues(current => ({
        ...current,
        job: {
          id: job!.id!,
          code: job!.code!,
          name: job!.name!,
        },
        toPosition: {
          id: toPosition!.id!,
          code: toPosition!.code!,
          name: toPosition!.name!,
        },
        workItemCosmosKey: task.workItem?.cosmosKey,
        taskCosmosKey: task.cosmosKey,
        task: task,
      }))
    }
  }, [taskQuery])

  /** Set focus to the field when the form opens */
  const jobFieldRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    jobFieldRef?.current?.focus()
  }, [jobFieldRef])

  const handleSetQuantity = (quantity: number) => {
    fc.setFieldValue('quantity', quantity)
  }

  return (
    <>
      {task && <TaskDetailsSection task={task} />}
      <Section>
        <div style={{ padding: '0.5rem' }}>
          <Formik.Field component={MFieldConnector} name={nameof<TransferEntryForm>('job')} color={'#6a707e'}>
            <FieldJob title={t('ASSIGN_JOB')} ref={jobFieldRef} />
          </Formik.Field>
          <Formik.Field component={MFieldConnector} name={nameof<TransferEntryForm>('fromPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`FROM_POSITION`} neverDisable />
          </Formik.Field>
          <div style={{ display: 'none' }}>
            <Formik.Field component={MFieldConnector} name={nameof<TransferEntryForm>('jobInventoryDetail')}>
              <FieldJobInventoryDetail title={t('ASSIGN_JOB_INVENTORY_DETAIL')} />
            </Formik.Field>
          </div>
          <Formik.Field component={MFieldConnector} name={nameof<TransferEntryForm>('toPosition')}>
            <FieldPosition title={t('ASSIGN_POSITION')} label={`TO_POSITION`} neverDisable />
          </Formik.Field>
          <FieldComboQuantityPerc
            quantity={fc.values.quantity}
            maxQuantity={fc.values.originalQuantity}
            unitOfMeasure={fc.values.jobInventoryDetail?.unitOfMeasure ?? ''}
            setQuantity={(quantity: number) => handleSetQuantity(quantity)}
          />
        </div>
      </Section>
      <div style={{ marginTop: '1rem' }}>
        <Section>
          <SelectedJobInventoryDetails jobInventoryDetail={fc.values.jobInventoryDetail} showPosition={false} />
        </Section>
      </div>
    </>
  )
}

TransferForm.useSchema = () => {
  const { t } = useTranslation()

  const [schema] = useState(
    (): Yup.SchemaOf<TransferEntryForm> =>
      Yup.object().shape({
        job: Yup.object()
          .shape({
            id: Yup.string().required('Job is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .required('Job is a required field')
          .nullable()
          .label(t(`JOB`)),
        jobInventoryDetail: Yup.mixed().required().label(t(`JOB_INVENTORY_DETAIL`)),
        fromPosition: Yup.object()
          .shape({
            id: Yup.string().nullable(),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .nullable(),
        toPosition: Yup.object()
          .shape({
            id: Yup.string().required('Position is a required field'),
            name: Yup.string().nullable(),
            code: Yup.string().nullable(),
          })
          .test(`position`, t('NOTIFICATION_POSITION_INVALID'), (input, context) => {
            if (!context.parent.isWorkItemTransfer && context.parent.jobInventoryDetail?.position) {
              return input.code !== context.parent.jobInventoryDetail.position.code
            } else return true
          })
          .required()
          .nullable()
          .label(t(`POSITION`)),
        originalQuantity: Yup.number().nullable().defined().label('originalQuantity'),
        quantity: Yup.number()
          .transform((value, originalValue) => {
            if (originalValue && typeof originalValue === 'string') {
              const processedValue = originalValue.replace(',', '.')
              return parseFloat(processedValue)
            }
            return value
          })
          .min(0.5)
          .required()
          .label(t(`QUANTITY`))
          .typeError('Quantity is a required field'),
        isWorkItemTransfer: Yup.boolean().required(),
        workItemId: Yup.mixed().optional().nullable(),
        workItemCosmosKey: Yup.mixed().optional().nullable(),
        taskCosmosKey: Yup.mixed().optional().nullable(),
        task: Yup.object().nullable(),
      }),
  )

  return schema
}
