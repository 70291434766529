import { Formik, MAudio, ValidationIssues, withFormikCompareFix } from '@mprise/react-ui'
import { useTranslation } from 'react-i18next'
import { JobInventoryDetail, ScannedUploadCarrier } from '../../../shared/interfaces'
import { useHistory } from '../../../shared/use-history'
import { FlashAlerts } from '../../../shared/flash-alerts'
import { useAppSettingsContext } from '../../../context/AppSettingsContext'
import { useLocalState } from '../../../shared/local-state'
import { DialogFormik } from '../../../mprise-light/DialogFormik'
import { SavingSwitchPanel } from '../../../shared/saving-switch-panel'
import { MutationErrorMessage } from '../../../shared/apollo'
import { UploadCarriersForm } from './UploadCarriersForm'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { fail } from '../../../shared/typescript'
import { REPORT_UPLOAD_AND_TRANSFER_CARRIERS_TO_POSITION } from '../../../gql/mutation/reportUploadAndTransferCarriersToPosition'
import { useApolloClient, useMutation } from '@apollo/client'
import { STOP_TASK } from '../../../gql/mutation/statusChange/statusChange'
import { parseFloatQuantity } from '../../../shared/formats'
import { Maybe } from '../../../shared/enums'
import { STOP_TIME_REG } from '../../../gql/mutation/timeReg'

export interface UploadCarriersEntryForm {
  job: Maybe<{ id: string; name: string; code: Maybe<string> }>
  jobInventoryDetail: Maybe<JobInventoryDetail>
  fromPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  toPosition: Maybe<{ id: string; name: string; code: Maybe<string> }>
  carrierCode: Maybe<{ id: string; name: string; code: Maybe<string> }>
  carrierQuantity: number | null
  scannedCarriers: Maybe<ScannedUploadCarrier[]>
  isWorkItemTransfer: boolean
  workItemId: Maybe<string>
  workItemCosmosKey: Maybe<string>
  taskCosmosKey: Maybe<string>
}

export const UploadCarriersRoute = () => {
  const h = useHistory()
  const { t } = useTranslation()
  const alerts = FlashAlerts.useAlert()
  const apollo = useApolloClient()
  const cache = apollo.cache

  const schema = UploadCarriersForm.useSchema()

  const { resource, numberFormat } = useAppSettingsContext()
  const resourceId = resource?.id ?? h.push('/')

  // If Transfer is used by a redirect of a WorkOrder: Prefill form
  const workItemId = useParams().workItemId ?? null
  const taskId = useParams().taskId
  // If phaseCode is defined: do an automatic phase transition at transfer
  const phaseCodeText = useParams().phaseCode
  const phaseCode = phaseCodeText === 'null' ? null : phaseCodeText

  const [stopTaskMutation, stopTaskState] = useMutation(STOP_TASK)
  const [stopTimeReg] = useMutation(STOP_TIME_REG)

  const [initialValues] = useLocalState((): UploadCarriersEntryForm => {
    return withFormikCompareFix({
      job: { id: '', name: '', code: '' },
      fromPosition: { id: '', name: '', code: '' },
      jobInventoryDetail: null,
      toPosition: { id: '', name: '', code: '' },
      carrierCode: { id: '', name: '', code: '' },
      carrierQuantity: null,
      scannedCarriers: null,
      isWorkItemTransfer: workItemId ? true : false,
      workItemId: workItemId ?? null,
      workItemCosmosKey: null,
      taskCosmosKey: null,
    })
  }, [])

  const handleClose = async (autoFinished?: boolean) => {
    if (workItemId && taskId && !autoFinished) {
      await stopTaskMutation({
        variables: {
          workItemId: +workItemId,
          taskId: +taskId,
          currentResourceId: +resourceId,
        },
      }).then(response => {
        const task = response.data.stopTask
        stopTimeReg({
          variables: {
            workItemId: task.workItem.cosmosKey,
            taskId: task.cosmosKey,
          },
        })
      })
    }
    h.goBack()
  }

  const [reportUploadAndTransferCarriersToPosition, reportUploadAndTransferCarriersToPositionState] = useMutation(
    REPORT_UPLOAD_AND_TRANSFER_CARRIERS_TO_POSITION,
  )

  const handleSubmitUploadCarriers = async (
    form: UploadCarriersEntryForm,
    actions: Formik.FormikHelpers<UploadCarriersEntryForm>,
  ) => {
    actions.resetForm()

    if (!resourceId) {
      fail('expects resource id')
    }

    let autoFinished = false
    const scanned = form.scannedCarriers ?? []
    const carrierCodesAndQuantities = scanned.map(entry => {
      return {
        carrierCode: entry.carrierCode,
        quantity: parseFloatQuantity(entry.quantity, numberFormat),
      }
    })

    await reportUploadAndTransferCarriersToPosition({
      variables: {
        originJobInventoryDetailId: +form.jobInventoryDetail?.id!,
        carrierCodesAndQuantities: carrierCodesAndQuantities,
        destinationPositionId: +form.toPosition?.id!,
        resourceId: +resourceId,
        destinationPhase: phaseCode,
        workItemId: workItemId ? +workItemId : null,
      },
    }).then(response => {
      if (response.data) {
        MAudio.scanSuccess()
        const successCount = response.data.uploadAndTransferCarriersToPositionGH?.succeeded?.length
        alerts.push(t('X_OUT_OF_Y_SUCCESSFULLY_POSTED', { x: successCount, y: scanned.length }), 'success')
        autoFinished = response.data.uploadAndTransferCarriersToPositionGH.autoFinished
        if (autoFinished) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'searchWorkItems' })
        }
      }
    })

    if (workItemId && taskId) {
      if (form.workItemCosmosKey && form.taskCosmosKey && autoFinished) {
        stopTimeReg({
          variables: {
            workItemId: form.workItemCosmosKey,
            taskId: form.taskCosmosKey,
          },
        })
        handleClose(autoFinished)
      }
    }
  }

  return (
    <>
      <Helmet title={t('UPLOAD_CARRIER_CODES')} />

      <Formik.Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmitUploadCarriers}>
        <DialogFormik
          minWidth='xl'
          title={t('UPLOAD_CARRIER_CODES')}
          onClose={() => handleClose(false)}
          onCloseWithContext={() => handleClose(false)}
          open={true}
        >
          <SavingSwitchPanel mutation={[reportUploadAndTransferCarriersToPositionState, stopTaskState]}>
            <ValidationIssues />
            <MutationErrorMessage mutation={[reportUploadAndTransferCarriersToPositionState, stopTaskState]} />
            <UploadCarriersForm />
          </SavingSwitchPanel>
        </DialogFormik>
      </Formik.Formik>
    </>
  )
}
