import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { DialogHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { SearchInput } from '../../components/SearchInput'
import { Section, SectionList } from '../../components/Section'
import { defined } from '../../shared/typescript'
import { useHistory } from '../../shared/use-history'
import { useDebounceValue } from '../../shared/debounce-value'
import { DialogBackButton, DialogSaveButton } from '../../shared/dialog/DialogButtons'
import { MColor } from '@mprise/react-ui'
import { GET_COMPANIES } from '../../gql/companies'
import { useLazyQuery } from '@apollo/client'
import { QueryErrorMessage } from '../../shared/apollo'

export const SettingsCompanyRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounceValue(search, 500)

  const [getCompanies, { error, data: companies, refetch, loading }] = useLazyQuery(GET_COMPANIES, {
    onError: console.error,
  })

  const { company, setCompany } = useAppSettingsContext()
  const [selected, setSelected] = useState(company)

  useEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    if (debouncedSearch) {
      getCompanies({
        variables: {
          filter: {
            name: debouncedSearch,
          },
        },
      })
    } else {
      getCompanies()
    }
  }, [debouncedSearch])

  const handleCancel = () => {
    h.push('/settings')
  }

  const handleSave = () => {
    if (selected?.id !== company?.id) {
      setCompany(selected)
    }
    h.push('/settings')
  }

  return (
    <>
      <DialogHeader
        title={t('TITLE_SETTINGS')}
        backward={<DialogBackButton onClick={handleCancel} />}
        forward={<DialogSaveButton onClick={handleSave} />}
      />
      <QueryErrorMessage query={{ error, refetch, loading }} />
      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_COMPANY')}>
            <div className='ml-search'>
              <SearchInput onChange={setSearch} />
            </div>
            <List>
              {companies &&
                (companies.companies ?? []).filter(defined).map((company: any, idx: number) => (
                  <ListItem
                    key={company.id ?? idx}
                    icon={<MaterialIcon value='place' style={{ fontSize: '2rem' }} />}
                    primary={company.name}
                    action={
                      selected?.id === company.id ? (
                        <MaterialIcon value='check' style={{ color: MColor.primary }} />
                      ) : null
                    }
                    onClick={() =>
                      company &&
                      setSelected({
                        id: company.id,
                        name: company.name ?? '',
                      })
                    }
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
