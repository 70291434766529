import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { MField } from '@mprise/react-ui'
import { Maybe } from '../enums'
import { JobInventoryDetail } from '../interfaces'
import { FieldSelectionQuantity } from './SelectionFieldQuantity'
import { ValidationFriendlyWarning } from './ValidationFriendlyWarning'
import { formatUnitOfMeasure } from '../formats'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { FieldTitle } from '../../components/FieldTitle'
import { FieldPercentage } from './FieldPercentage'

export type FormType = {
  jobInventoryDetail: Maybe<JobInventoryDetail>
  originalQuantity: Maybe<number>
  quantity: Maybe<number>
  percentage: Maybe<number>
}

export const FieldComboQuantityPerc = ({
  quantity,
  maxQuantity,
  unitOfMeasure,
  setQuantity,
}: {
  quantity: Maybe<number>
  maxQuantity: Maybe<number>
  unitOfMeasure: string
  setQuantity: (quantity: number) => void
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { defaultNumberInputMethod } = useAppSettingsContext()

  const [percentage, setPercentage] = useState<number | undefined>(
    maxQuantity && quantity ? Math.floor((quantity / maxQuantity) * 100) : undefined,
  )
  const [isRegisteringInPercentage, setIsRegisteringInPercentage] = useState(defaultNumberInputMethod === 'PERCENTAGE')

  const exceedsMaxQuantity = quantity && maxQuantity ? quantity > maxQuantity : false
  const exceedsMaxPercentage = !!maxQuantity && isRegisteringInPercentage && !!percentage && percentage > 100

  const handlePercentageToggle = () => {
    if (!isRegisteringInPercentage && quantity && quantity > 0 && maxQuantity) {
      const newPercentage = Math.floor((quantity / maxQuantity) * 100)
      setPercentage(newPercentage)
    }
    setIsRegisteringInPercentage(!isRegisteringInPercentage)
  }

  const handlePercentageChange = (percentage: number) => {
    setPercentage(percentage)

    if (percentage && maxQuantity) {
      const newQuantity = (maxQuantity * percentage) / 100
      const formattedQuantity = unitOfMeasure === 'Containers' ? newQuantity : Math.ceil(newQuantity)
      setQuantity(formattedQuantity)
    }
  }

  const handleQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity)

    if (maxQuantity) {
      const newPercentage = Math.floor((newQuantity / maxQuantity) * 100)
      setPercentage(newPercentage)
    }
  }

  return (
    <>
      {!isRegisteringInPercentage ? (
        <MField
          vertical
          name=''
          label=''
          value={quantity ?? undefined}
          onChange={(quantity: number) => handleQuantityChange(quantity)}
        >
          <FieldSelectionQuantity
            unitOfMeasure={unitOfMeasure}
            title={`${t(`QUANTITY`)} ${unitOfMeasure ? `(${percentage ?? 0}%)` : ''}`}
          />
          <ValidationFriendlyWarning open={exceedsMaxQuantity} text={t(`WARNING_EXCEEDS_QUANTITY`)} />
        </MField>
      ) : (
        <MField
          vertical
          name=''
          label=''
          value={percentage}
          onChange={(percentage: number) => handlePercentageChange(percentage)}
        >
          <FieldTitle
            title={`${t('QUANTITY')} ${
              unitOfMeasure ? `(${quantity ?? 0} ${formatUnitOfMeasure(unitOfMeasure)})` : ''
            }`}
          />
          <div className='gh-field-quantity'>
            <FieldPercentage />
          </div>
          <ValidationFriendlyWarning open={exceedsMaxPercentage} text={t(`WARNING_EXCEEDS_QUANTITY`)} />
        </MField>
      )}
      <div className={classes.underLinedText} onClick={handlePercentageToggle}>
        {isRegisteringInPercentage ? t('REGISTER_QUANTITY') : t('REGISTER_PERCENTAGE')}
      </div>
    </>
  )
}

const useStyles = makeStyles(() => ({
  underLinedText: {
    color: 'black',
    fontSize: '14px',
    fontWeight: 200,
    marginBottom: '0.5rem',
    padding: '0.5rem',
    '&:hover': {
      color: 'grey',
    },
    textDecoration: 'underline',
  },
}))
