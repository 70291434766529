import { WorkItemTemplateTaskOption, WorkResultType, WorkStatus } from '../../shared/enums'

type TaskValue = {
  id: string
  status: WorkStatus
  types: WorkResultType[]
  taskOptions: WorkItemTemplateTaskOption[]
}

type WorkItemValue = {
  id: string
  phaseCode: string
  tasks: any[]
  jobs: any[]
  jobInventoryPutAway: any[]
  itemConsumption: any[]
  itemOutput: any[]
}

export function redirectTask(workItem: WorkItemValue, task: TaskValue, history: any) {
  const workItemId = workItem.id
  const taskId = task.id

  const hasJobInventoryPutAway = workItem.jobInventoryPutAway?.length
  const hasMultipleJobInvPutAway = hasJobInventoryPutAway > 1
  const isItemConsumption = workItem.itemConsumption?.length
  const isItemOutput = workItem.itemOutput?.length
  const phaseCode = workItem.phaseCode
  const taskOptions = task.taskOptions

  if (hasJobInventoryPutAway && !isItemConsumption) {
    if (hasMultipleJobInvPutAway) {
      history.push(`/sort-and-transfer/${taskId}`)
    } else if (taskOptions.includes(WorkItemTemplateTaskOption.PutOnCarriersGh)) {
      history.push(`/transfer/uploadcarriers/${workItemId}/${taskId}/${phaseCode}`)
    } else if (taskOptions.includes(WorkItemTemplateTaskOption.MoveCarriersToLocationGh)) {
      history.push(`/transfer/carriercodes/${workItemId}/${taskId}/${phaseCode}`)
    } else if (taskOptions.includes(WorkItemTemplateTaskOption.OffloadCarriersGh)) {
      history.push(`/transfer/carriercodes/${workItemId}/${taskId}/${phaseCode}`)
    } else if (taskOptions.includes(WorkItemTemplateTaskOption.AdvancedAreaRegistrationGh)) {
      history.push(`/area-registration/${workItem.jobs[0].code}`)
    } else if (taskOptions.includes(WorkItemTemplateTaskOption.CropMaintenanceGh)) {
      history.push(`/crop-maintenance/${workItemId}/${taskId}`)
    } else {
      history.push(`/transfer/job/${workItemId}/${taskId}/${phaseCode}`)
    }
  } else if (isItemOutput) {
    history.push(`/transfer/job-pick/${workItemId}/${taskId}/${phaseCode}`)
  } else if (isItemConsumption) {
    history.push(`/transfer/create-inventory/${workItemId}/${taskId}`)
  }
}
