import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { availableLanguages, useAppSettingsContext } from '../../context/AppSettingsContext'
import { Card } from '../../components/Card'
import { DialogHeader } from '../../components/Header'
import { MaterialIcon } from '../../components/Icon'
import { List, ListItem } from '../../components/List'
import { Section, SectionList } from '../../components/Section'
import { useHistory } from '../../shared/use-history'
import { DialogBackButton, DialogSaveButton } from '../../shared/dialog/DialogButtons'
import { MColor } from '@mprise/react-ui'

export const SettingsLanguageRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { language, setLanguage } = useAppSettingsContext()
  const [selected, setSelected] = useState(language)

  const handleCancel = () => h.goBack()
  const handleSave = () => {
    setLanguage(selected)
    h.goBack()
  }

  return (
    <>
      <DialogHeader
        title={t('TITLE_SETTINGS')}
        backward={<DialogBackButton onClick={handleCancel} />}
        forward={<DialogSaveButton onClick={handleSave} />}
      />

      <SectionList>
        <Section>
          <Card header={t('TITLE_CHOOSE_LANGUAGE')}>
            <List>
              {availableLanguages
                .map(x => ({ id: x, name: t(`LANGUAGE_${x.toUpperCase()}`) }))
                .map(x => (
                  <ListItem
                    key={x.id}
                    icon={<MaterialIcon value='language' style={{ fontSize: '2rem' }} />}
                    primary={x.name}
                    action={
                      selected.id === x.id ? <MaterialIcon value='check' style={{ color: MColor.primary }} /> : null
                    }
                    onClick={() => x && setSelected(x)}
                  />
                ))}
            </List>
          </Card>
        </Section>
      </SectionList>
    </>
  )
}
